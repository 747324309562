.box-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.box-column {
  position: relative;
  grid-row: 1;
  padding: 30px 50px;
}

.box-column:nth-child(2) {
  padding: 10px 20px;
}
.gp-left-column {
  position: relative;
  border-right: 1px solid #d5d5d5;
  background-color: antiquewhite;
  background-image: url(https://harnishdesign.net/demo/html/oxyy/images/login-bg-2.jpg);
  background-position: top;
}
.cm-intro-text {
  position: relative;
  z-index: 2;
  /*color: #4d4d4d;*/
  color: #fff;
}
.cm-intro-text h2 {
  font-size: 48px;
}

.height100 {
  height: 100%;
}
.cm-box {
  max-width: 90%;
  width: 100%;
  height: 590px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  background-color: #fff;
  border-radius: 20px;
  margin-top: 30px;
  box-shadow: 0px 3px 8px 0px #aeaeae;
}
.gp-form-title {
  font-size: 28px;
  color: #4d4d4d;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}
.cm-intro-text p.gp-subtext {
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}
.cm-intro-text p.gp-bold-500 {
  font-weight: 500;
}
.gp-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*    background-color: rgba(0,0,0, 1);*/
  /*    background-color: rgba(12,47,85,1);*/
  background-image: linear-gradient(
    to right top,
    #0c2f55,
    #23213d,
    #221627,
    #180d14,
    #000000
  );
  opacity: 0.8;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.properties-wrapper {
  scrollbar-width: auto;
  scrollbar-color: #d6d6d6 #ffffff;
}

/* Chrome, Edge, and Safari */
//   .properties-wrapper::-webkit-scrollbar {
//     width: 6px;
//   }

//   .properties-wrapper::-webkit-scrollbar-track {
//     background: #ffffff;
//   }

//   .properties-wrapper::-webkit-scrollbar-thumb {
//     background-color: #d6d6d6;
//     border-radius: 10px;
//     border: 1px solid #ffffff;
//   }
