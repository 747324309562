.progress-bar {
  display: flex;
  display: grid;
  grid-template-columns: 0.5fr 0.6fr 1fr 0.6fr 0.6fr 0.6fr 0.6fr;
  column-gap: 30px;
  background-color: #ffffff;
  padding: 10px;
  padding-right: 0px;
  width: 100%;
  height: auto;
  text-align: left;
  border-radius: 0px;
  margin-bottom: 2px;
  font-family: sans-serif;
  font-size: 12px;

  .progress {
    margin-left: 50px;
  }

  .processing {
    margin-right: 200px;
  }
}
