.customTagListTable-container {
  .content {
    display: grid;
    grid-template-columns: 1.9fr 1fr 1fr 0.6fr 1fr 0.7fr;
    column-gap: 40px;
    align-items: center;
    background: #f9fffb;
    border: 1px solid #c5ebc9;
    // border-radius: 12px;
    padding: 7px;
    font-size: 12px;
    line-height: 16px;
    color: #222222;

    .tag-name{
      width: 40%;
    }

    .Select-container {
      margin-bottom: 5px;
    }

    .flex-display {
      display: flex;
      column-gap: 40px;
      text-align: center;

      .logo {
        width: 50px;
        height: 40px;
        margin-right: 10px;
        size: 0cap;
      }

      .left-text {
        text-align: center;
      }
    }

    .flex-displays {
      display: flex;
      column-gap: 20px;
      align-items: center;
    }

    .right-buttons {
      display: flex;
      align-items: center;
      gap: 10px;
      background-color: #ffffff;
      border: none;
      padding: 0;
      cursor: pointer;

      .eye {
        background-color: #ffffff;
        border: none;
        color: darkgreen;
        font-size: medium;
      }

      .sel {
        background-color: #ffffff;
        border: none;
        color: darkgreen;
        font-size: medium;
        font-weight: bolder;
      }
    }
  }

  .preview-overlay {
    position: absolute;
    top: 20px;
    left: 70px;
    right: 70px;
    bottom: 10px;
    height: max-content;
    z-index: 999;

    .preview-content {
      position: absolute;
      top: 20px;
      left: 70px;
      right: 70px;
      bottom: 10px;
      height: max-content;
      padding: 10px;
      border-radius: 10px;
      padding-bottom: 50px;

      .closePreview {
        color: green;
        cursor: pointer;
        font-size: 20px;
        font-weight: 800;
        position: absolute;
        top: 15px;
        right: 13px;
        z-index: 4;
        padding: 0px 5px;
      }

    }

  }

  // .preview-overlay,
  // .preview-content {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }
}

@media only screen and (max-width: 1370px) {
  .customTagListTable-container {
    .content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 40px;
      row-gap: 10px;
      align-items: center;
      background: #f9fffb;
      border: 1px solid #c5ebc9;
      border-radius: 12px;
      padding: 7px;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height */
      color: #222222;
    }
  }
}
