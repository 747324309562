@import '../../styles/ConstVariable';
.reset-password-container {
  padding: 0;
  margin: 0;
  background-color: #023050;
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  height: calc(100vh - 70px);
  overflow: hidden;
  position: relative;

  .reset-container {
    max-width: 500px;
    width: 100%;
    background-color: #fff;
    height: auto;
    padding: 50px 80px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .logo {
      display: flex;
      justify-content: center;
      img {
        width: 100%;
      }
    }

    button {
      width: 100%;
      display: block;
      background-color: #07548b;
      padding: 10px 20px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      margin-top: 30px;
      text-decoration: none;
      border: none;
    }

    input {
      border-color: #333;
      border-radius: 0;
    }

    .title-container .title {
      font-family: Poppins, sans-serif;
      line-height: 42px;
      color: #222222;
      font-size: 32px;
      text-align: center;
      font-weight: 300;
      margin-bottom: 30px;
    }
  }
}
