.webAudience-container {
  margin-top: 20px;
  .url-text {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .url-textbox {
    display: flex;
    input[type='text'] {
      width: 300px;
      height: 30px;
      border: 2px solid darkgreen;
      border-radius: 5px;
      padding: 5px;
      font-size: 14px;
    }
  }
}
