@import '../../styles/ConstVariable';
.pdfPreview-container{
    background-color: #e7fff4;

    border-radius: 20px;
    padding: 20px;
    button{
        background: $cancelButtonBackgroundColor;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        color: $cancelButtonTextColor;
        border-radius: 7px;
        width: fit-content;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-left: 2px;
        margin-right: 2px;
        border: 2px solid black;
        margin-bottom: 4px;
        // height: 43px;
        
        cursor: pointer;
        
    }
}

.pdfPreview-pop-up .position-helper {
    position: relative;
}
.pdfPreview-pop-up .position-helper .closePfdPreview {
    position: absolute;
    top: 12px;
    right: 15px;
    cursor: pointer;
}