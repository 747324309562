.logo img {
    max-width: 230px;
    display: flex;
}

.main-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.logo {
    position: relative;
    top: 2px;
}

.logo .default-logo {
    display: none;
}

header.active {
    top: 0;
    background-color: #ededed;
    transition: 0.3s ease;
    position: fixed;
    z-index: 99999;
}

header.active .logo .white-logo {
    display: none;
}

header.active .logo .default-logo {
    display: block;
}

.nav {
    height: 65px;
    width: 100%;
    padding: 5px 80px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: linear-gradient(270deg, rgba(7, 169, 122, 1) 0%, rgba(5, 84, 141, 1) 56%);
}

.nav>.nav-header {
    display: inline;
}

.nav>.nav-header>.nav-title {
    display: inline-block;
    font-size: 22px;
    color: #fff;
    ;
    padding: 10px 10px 10px 10px;
}

.nav>.nav-btn {
    display: none;
}

.nav>.nav-links {
    /* display: inline; */
    /* float: right; */
    font-size: 18px;
    position: absolute;
    /* float: right; */
    right: 100px;
}

.nav>.nav-links>ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    column-gap: 30px;
}

.nav>.nav-links>ul>li>a {
    display: inline-block;
    padding: 13px 10px 13px 10px;
    text-decoration: none;
    color: #fff;
    ;
    text-decoration: none;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.nav>.nav-links>ul>li>link>a {
    display: inline-block;
    padding: 13px 10px 13px 10px;
    text-decoration: none;
    color: #fff;
    ;
    text-decoration: none;
    font-size: 14px;
    display: flex;
    align-items: center;
}

/*.nav > .nav-links > .sub-nav span i {
	    transition: 0.3s ease;
	}*/
.nav>.nav-links>ul>li a span i {
    transform: rotate(0deg);
    transition: 0.3s ease;
}

header.active .nav>.nav-links>ul>li>a {
    color: #333;
}

/*ul.submenu > li {
padding: 10px;
}*/
ul.submenu>li>a {
    color: #333;
    text-decoration: none;
    width: 100%;
    display: block;
    padding: 12px;
    font-size: 14px;
}


.nav>#nav-check {
    display: none;
}

li.sub-nav {
    position: relative;
}

li.sub-nav a span {
    margin-left: 6px;
}

li.sub-nav ul {
    display: none;
    position: absolute;
    background-color: #fff;
    color: #333;
    min-width: 250px;
    height: 0;
    overflow-y: hidden;
    transition: 0.3s ease;
    /*	border-radius: 10px*/
}

li.sub-nav input[type="checkbox"] {
    display: none;
}

/*a.header-contact-btn {
    background-color: #ffffff;
    color: #06548d !important;
    padding: 8px 15px !important;
    border: 0;
    border-radius: 50px;
    font-size: 14px;
    font-family: 'Epilogue', sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    cursor: pointer;
}*/
a.header-contact-btn {
    padding: 8px 15px !important;
    border: 1px solid;
    font-weight: 300;
    border-radius: 50px;
    font-size: 14px;
    font-family: 'Epilogue', sans-serif;
    gap: 8px;
    cursor: pointer;
    width: max-content;
}

.flex:has(.header-contact-btn) {
    display: flex;
    column-gap: 10px;
}

/*a.header-contact-btn:hover{
	color: rgba(255,255,255,0.7) !important;
}
a.header-contact-btn:hover svg{
	fill: rgba(255,255,255,1) !important;
}*/
a.header-contact-btn:hover {
    background-color: #ffffff;
    border: 1px solid #ffffff;
    color: #05548d !important;
}

a.header-contact-btn svg {
    fill: #03558e;
}

header {
    position: fixed;
    width: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.2);
}

@media (max-width:768px) {
    .container {
        padding: 0 20px;
    }

    .logo .white-logo {
        display: none;
    }

    .logo .default-logo {
        display: block;
    }

    .logo img {
        max-width: 50%;
    }

    .nav {
        height: 50px;
        padding: 0 20px;
        background-color: #ededed;
        justify-content: left;
    }

    .nav>.nav-btn {
        display: inline-block;
        position: absolute;
        right: 0px;
        top: 0px;
    }

    .nav>.nav-btn>label {
        display: inline-block;
        width: 50px;
        height: 50px;
        padding: 13px;
    }

    /*.nav > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
		background-color: rgba(0, 0, 0, 0.3);
	}*/
    .nav>.nav-btn>label>span {
        display: block;
        width: 25px;
        height: 10px;
        border-top: 2px solid #333;
    }

    .nav>.nav-links {
        position: absolute;
        display: block;
        width: 100%;
        background-color: #ededed;
        height: 0px;
        transition: all 0.3s ease-in;
        overflow-y: hidden;
        top: 50px;
        left: 0px;
    }

    .nav>.nav-links>ul {
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
    }

    .nav>.nav-links ul li {
        width: 100%;
    }

    .nav>.nav-links>ul>li a span i {
        transition: all 0.3s ease;
    }

    .nav>.nav-links>ul>li>a {
        display: block;
        width: 100%;
        color: #333;
    }

    .nav>#nav-check:not(:checked)~.nav-links {
        height: 0px;
    }

    .nav>#nav-check:checked~.nav-links {
        height: calc(100vh - 50px);
        overflow-y: auto;
        padding: 0 20px;
    }

    li.sub-nav input[type="checkbox"] {
        display: block;
    }

    li.sub-nav input[type="checkbox"]:checked~ul {
        height: 100%;
        position: relative;
        display: block;
        list-style: none;
        padding: 0;
    }

    li.sub-nav input[type="checkbox"] {
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        height: 50px;
        opacity: 0;
    }

    li.sub-nav input[type="checkbox"]:not(:checked)~ul.submenu {
        transition: all 0.5s ease-out;
        top: -100px;
        overflow-y: hidden;
    }

    li.sub-nav input[type="checkbox"]:checked~ul.submenu {
        transition: all 0.5s ease-out;
        top: 0;
        overflow-y: auto;
    }

    a.header-contact-btn {
        margin-top: 20px;
    }

    .banner-content {
        flex-direction: column;
        width: 100%;
        padding: 0 20px;
        gap: 0px;
    }

    .banner-text {
        width: 100%;
        text-align: left;
    }

    .banner-content h1 {
        font-size: 28px;
        line-height: normal;
    }

    .banner-content p {
        font-size: 16px;
        line-height: 24px;
    }

    .banner-hero iframe {
        width: 100%;
        height: 190px;
    }

    .banner-hero img {
        display: none;
    }



    .pad-t0-mbl {
        padding-top: 0;
    }

    .policy-bg-para {
        width: 100%;
    }
}