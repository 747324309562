.ValidationMessage-container {
  .tooltip {
    position: relative;
  }
  .warningMessage {
    text-align: end;
    padding: 10px;
    border-radius: 5px;
    background-color: orange;
    color: white;
    display: block;
    margin-left: auto;
    z-index: 1;
    margin-bottom: 2px;
    font-size: 14px;
    width: fit-content;
  }

  .warningMessage::before {
    background-color: orange;
    content: ' ';
    display: block;
    position: absolute;
    width: 20px;
    height: 15px;
  }

  .warningMessage::before {
    right: 9%;
    bottom: -2.5px;
    transform: rotate(19deg);
    z-index: -1;
  }
}
