table.fwg-payment-detail tr td:first-child {
    width: 50px;
    text-align: center;
    vertical-align: bottom;
}
table.fwg-payment-detail tr td:nth-child(2) {
    //width: 140px;
}
table.fwg-payment-detail tr td {
    vertical-align: middle;
    line-height: normal;
    border: none;
    background-color: #fff;
    padding: 4px;
}
