@import '../../styles/ConstVariable';

.previousButton-container {
  width: fit-content;
  button {
    // display: flex;
    // justify-content: space-around;
    // align-items: center;
    // background: $previousButtonBackground;
    // font-weight: 700;
    // font-size: 16px;
    // line-height: 21px;
    // border-radius: 12px;
    // width: 130px;
    // height: 43px;
    // color: $previousButtonTextColor;
    // cursor: pointer;
    // border: none;

    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid #1976d2;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    border-radius: 5px;
    width: 150px;
    height: 40px;
    color: #1976d2;
    cursor: pointer;
  }

  button span svg {
    margin-right: 6px;
    height: 9px;
  }
}
