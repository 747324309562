@import '../../styles/ConstVariable';

.edit-delete-container{

    button{
        color: $editDeleteButtonTextColor;
        background-color: $editDeleteButtonBackgroundColor;
        border-radius: 12px;
        height: 42px;
        border: none;
        min-width: 79px;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;

    }
    
}