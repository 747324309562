@page {
  margin-top: 2cm;
  margin-left: 0cm;
  margin-right: 0cm;
  margin-bottom: 0px;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.5;
  font-size: 13px;
  color: #333333;
}

.fwg-page-content-pad {
  padding: 10px 70px;
}

// .fwg-logo-block {
//   position: relative;
//   height: 50px;
// }

// .fwg-header-logo {
//   position: absolute;
//   top: 30px;
//   width: 100%;
// }

// .fwg-logo-block svg {
//   width: 100px;
//   position: absolute;
//   top: -30px;
//   left: 50px;
//   background-color: rgb(255 255 255);
//   padding: 10px 20px;
//   z-index: 1;
// }

// .fwg-logo-block .fwg-blue-line {
//   height: 5px;
//   display: block;
//   background-color: rgb(44 171 224);
//   position: relative;
// }

.fwg-font-bold {
  font-weight: bold;
}

.fwg-address1 {
  text-align: right;
}

.fwg-address2 {
  display: flex;
  justify-content: space-between;
}

.fwg-marg-t20 {
  margin-top: 20px;
}

.fwg-text-right {
  text-align: right;
}

.fwg-reference-details {
  font-weight: bold;
}

.fwg-logo-blue {
  color: #2cabe0;
}

table.fwg-payment-detail tr td {
  vertical-align: middle;
  line-height: normal;
}

table.fwg-your-signed tr td {
  vertical-align: middle;
  line-height: normal;
}

table.fwg-your-signed tr td:first-child {
  width: 50px;
  text-align: center;
  vertical-align: bottom;
}

.fwg-font-9 {
  font-size: 9px;
}

.fwg-footer {
  position: relative;
}

.fwg-footer-logo {
  position: absolute;
  bottom: 80px;
  width: 100%;
}

.fwg-footer-logo svg {
  width: 100px;
  position: absolute;
  top: -31px;
  left: 50px;
  background-color: #fff;
  padding: 10px 20px;
  z-index: 1;
}

.fwg-footer span.fwg-blue-line {
  height: 5px;
  display: block;
  background-color: #2cabe0;
  position: relative;
}

table.fwg-payment-detail tr td:first-child {
  width: 50px;
  text-align: center;
  vertical-align: bottom;
}

table.fwg-payment-detail tr td:nth-child(2) {
  //   width: 140px;
}

.fwg-emplty100 {
  height: 100px;
}

.cm-display-flex-column {
  display: flex;
  flex-direction: column;
}
.cm-two-column {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cm-two-column > * {
  width: 50%;
}
