.SystemTemplateInfo-container {
  .content {
    display: grid;
    grid-template-columns: 0.6fr 1fr 0.6fr 0.6fr 0.6fr 0.6fr;;
    column-gap: 30px;
    align-items: center;
    background: #f9fffb;
    border: 1px solid #c5ebc9;
    border-radius: 12px;
    padding: 7px;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */
    color: #222222;

    .Select-container {
      margin-bottom: 5px;
    }

    .flex-display {
      display: flex;
      column-gap: 20px;
      align-items: center;
      .logo {
        width: 50px;
        height: 40px;
        margin-right: 10px;
        size: 0cap;
      }
    }

    .right-buttons {
      display: flex;
      align-items: center;
      gap: 10px;
      background-color: #ffffff;
      border: none;
      padding: 0;
      cursor: pointer;

      .eye {
        background-color: #ffffff;
        border: none;
        color: darkgreen;
        font-size: medium;
      }
      .sel {
        background-color: #ffffff;
        border: none;
        color: darkgreen;
        font-size: medium;
        font-weight: bolder;
      }
    }
  }

  .preview-overlay {    
    .preview-content {
      position: absolute;
      top: 20px;
      left: 70px;
      right: 70px;
      bottom: 10px;
      height: max-content;
      padding: 10px;
      border-radius: 10px;
      padding-bottom: 50px;
      .previewIframe-container{
        height: fit-content;
        padding-bottom: 10px;
        .preview-box{   
          padding: 0px;
          
          overflow: none;
          scroll-behavior: none;
        }
      }
      .add-margin{
        margin-bottom: 10px;
        margin-top: 10px;
        .closePreview{
          color: green;
          cursor: pointer;
          font-size: 20px;
          font-weight: 800;
          position: absolute;
          top: 15px;
          right: 15px;
          z-index: 4;
          padding: 0px 5px;
        }
      }
    
  }
  }

}



