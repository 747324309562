@import '../../styles/ConstVariable';

.fullScreen-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // background-color: #c5ebc9;
  z-index: 10;
  .preview-overlay,
  .preview-content {
    position: absolute;
    top: 20px;
    left: 70px;
    right: 70px;
    bottom: 10px;
    height: 100%;
  }

  .preview-overlay {
    .preview-content {
      background-color: #fff;
      padding: 10px;
      border-radius: 10px;
      padding-bottom: 70px;

      .paginationAndCloseButton {
        display: flex;
        align-items: center;
        .pagination-section {
          display: flex;
          justify-content: end;
          margin-bottom: 10px;
          margin-top: 10px;
          align-items: center;
          column-gap: 10px;
          width: 100%;
          color: #ffffff;
          div {
            border-radius: 50%;
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 24px;
            // padding: 5px;
            background-color: #c5ebc9;
            cursor: pointer;
          }
        }
      }
      .previewIframe-container {
        height: fit-content;
        padding-bottom: 5px;
        background-color: #ffffff;

        .preview-box {
          padding: 0;
          padding-bottom: 5px;
          overflow: none;
          scroll-behavior: none;
        }
      }

      .closePreview {
        display: flex;
        justify-content: flex-end;
        color: red;
        cursor: pointer;
        font-weight: 800;
        width: 90%;
      }
    }
  }

  iframe {
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
  }

  .print-button {
    background: $cancelButtonBackgroundColor;
    font-weight: 700;
    margin-left: auto;
    margin-top: 20px;
    display: block;
    font-size: 16px;
    line-height: 21px;
    border-radius: 12px;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    height: 43px;
    color: $cancelButtonTextColor;
    cursor: pointer;
    border: none;
  }
}

.preview-resize-container {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  position: sticky;
  top: 0;
  min-width: 620px;
  border-radius: 5px;
  width: max-content;
  min-width: auto;
  max-width: 100%;
  width: 100%;
  .previewIframe-container {
    flex: 1;
    padding: 10px;
    background: #e7fff4;
    border: 1px solid #c5ebc9;
    border-radius: 5px;
    padding-bottom: 0px;
    width: 100%;

    .titleSection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 10px;
      margin-right: 10px;

      .preview {
        font-weight: 800;
        font-size: 20px;
        line-height: 26px;
        color: #222222;
      }
      .pagination-section {
        display: flex;
        justify-content: end;
        margin-bottom: 10px;
        margin-top: 10px;
        align-items: center;
        column-gap: 10px;

        color: #ffffff;
        div {
          border-radius: 50%;
          width: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 24px;
          // padding: 5px;
          background-color: #c5ebc9;
          cursor: pointer;
        }

        .active {
          background-color: green;
        }
      }
      .fullscreen {
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: $linkColor;
        cursor: pointer;
      }
    }
    .noPreview {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 400px;

      .noPreviewText {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        color: #666666;
        margin-bottom: 10px;
      }

      .NoPreviewInfo {
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #666666;
      }
    }

    .preview-box {
      flex: 1;
      margin-top: 10px;
      background: white;
      border-radius: 5px;
      padding: 10px;
      color: #333;
      height: 400px;
      // overflow: auto;

      iframe {
        outline: none;
        border: none;
        // width: 100%;
        // height: 100%;
        height: 100% !important ;
      }
    }

    .print-button {
      background: $cancelButtonBackgroundColor;
      font-weight: 700;
      margin-left: auto;
      margin-top: 20px;
      display: block;
      font-size: 16px;
      line-height: 21px;
      border-radius: 12px;
      width: fit-content;
      padding-left: 20px;
      padding-right: 20px;
      height: 43px;
      color: $cancelButtonTextColor;
      cursor: pointer;
      border: none;
    }
  }
  .sidebar-resizer {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 10px;
    justify-self: flex-end;
    cursor: col-resize;
    resize: horizontal;
  }
}

.preview-overlay .preview-resize-container {
  max-width: max-content;
  position: relative;
}

@media only screen and (max-width: 940px) {
  .previewbox-container {
    padding: 10px;
    height: auto;
    background: #e7fff4;
    border: 1px solid #c5ebc9;
    border-radius: 16px;
  }
}

@media only screen and (max-width: 1492px) {
  .preview-resize-container {
    min-width: auto;
    max-width: 100%;
    width: 100%;
  }
}
