@import '../../styles/ConstVariable';

.previewbox-container {
  height: 100%;
  padding: 10px;
  background: #e7fff4;
  border: 1px solid #c5ebc9;
  border-radius: 16px;
  padding-bottom: 0px;

  .titleSection {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;

    .preview {
      font-weight: 800;
      font-size: 20px;
      line-height: 26px;
      color: #222222;
    }

    .fullscreen {
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      color: $linkColor;
    }
  }
  .noPreview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .noPreviewText {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: #666666;
      margin-bottom: 10px;
    }

    .NoPreviewInfo {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: #666666;
    }
  }

  .preview-box {
    margin-top: 10px;
    background: white;
    border-radius: 5px;
    padding: 10px;
    color: #333;
    height: 400px;
    overflow: auto;
  }

  .print-button {
    background: $cancelButtonBackgroundColor;
    font-weight: 700;
    margin-left: auto;
    margin-top: 20px;
    display: block;
    font-size: 16px;
    line-height: 21px;
    border-radius: 12px;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    height: 43px;
    color: $cancelButtonTextColor;
    cursor: pointer;
    border: none;
  }
}

@media only screen and (max-width: 940px) {
  .previewbox-container {
    padding: 10px;
    height: auto;
    background: #e7fff4;
    border: 1px solid #c5ebc9;
    border-radius: 16px;
  }
}
