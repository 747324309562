@import '../../styles/ConstVariable';

.selectSystemTemplateList-container {
  margin-top: 10px;

  margin-bottom: 10px;
  min-width: 880px;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  font-family: Aver Bold, sans-serif;

  .text-container {
    margin-left: 10px;
  }

  .flex-style {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .flex-style {
      margin-left: auto;

      button {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }

  .headings {
    display: grid;
    grid-template-columns: 0.5fr 0.6fr 1fr 0.6fr 0.6fr 0.6fr 0.6fr;
    column-gap: 30px;
    font-style: normal;
    padding: 7px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */
    color: #666666;
  }

  .line {
    margin-bottom: 10px;
  }

  .lists {
    display: grid;
    grid-template-columns: 0.5fr 0.6fr 1fr 0.6fr 0.6fr 0.6fr 0.6fr;
    column-gap: 30px;
    align-items: center;
    background: #f9fffb;
    border: 1px solid #c5ebc9;
    // border-radius: 12px;
    padding: 7px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */
    color: #222222;

    input[type='radio'] {
      width: fit-content;
    }

    .flex-display {
      display: flex;
      column-gap: 20px;
      align-items: center;
    }
  }

  .preview-overlay {
    .preview-content {
      position: absolute;
      top: 20px;
      left: 70px;
      right: 70px;
      bottom: 10px;
      height: max-content;
      padding: 10px;
      border-radius: 10px;
      padding-bottom: 50px;

      .previewIframe-container {
        height: fit-content;

        padding-bottom: 10px;

        .preview-box {
          padding: 0px;
          // padding-bottom: 5px;
          overflow: none;
          scroll-behavior: none;
        }
      }

      .add-margin {
        margin-bottom: 10px;
        margin-top: 10px;

        .closePreview {
          color: green;
          cursor: pointer;
          font-size: 20px;
          font-weight: 800;
          position: absolute;
          top: 15px;
          right: 15px;
          z-index: 4;
          padding: 0px 5px;
        }
      }
    }
  }

  .navigationButtons {
    display: flex;
    gap: 20px;
    margin-right: 20px;
    margin-left: 40%;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .navigationButtons {
    margin: 25px 0 15px;
    justify-content: center;
  }

  .cm-pagination {
    margin-right: 0;
  }

  .navigationButtons button {
    /* border: 1px solid; */
    min-width: 100px;
  }

  .eye {
    background-color: transparent;
    border: 0;
    /* padding: 0; */
}
}
