@import '../../styles/ConstVariable';
.CustomTag-container {
  // border: 1px solid #eeeeee;
  // border-radius: 32px;
  margin: 10px;
  width: 95%;
  height: fit-content;
  // background-color: $pageBackgroundColor;
  padding: 20px;
  font-family: $pageFontFamily;
  margin-left: 30px;
  margin-right: 30px;
  .text-container {
    margin-left: 10px;
  }
  .img {
    // background: rgba(249, 250, 245, 0.5);
    border: 1px solid #c5ebc9;
    border-radius: 8px;
    width: 68px;
    height: 61px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flex-style {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > .flex-style {
      margin-left: auto;

      button {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
  .headings {
    display: grid;
    grid-template-columns: 1fr 0.9fr 1fr 0.9fr 0.8fr 1fr 0.7fr;
    column-gap: 40px;
    font-style: normal;
    padding: 7px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */
    color: #666666;
  }
  .lists {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1370px) {
  .CustomTag-container {
    .headings {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 40px;
      row-gap: 10px;
      font-style: normal;
      padding: 7px;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height */
      color: #666666;
    }
  }
}
