.paginations {
  display: flex;
  align-items: center;
}

.pagination-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #c5ebc9;
  color: #ffffff;
  cursor: pointer;
  margin: 0 4px;
  transition: background-color 0.3s;

  &.active {
    background-color: #6e9c6e;
  }
}

.pagination-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #c5ebc9;
  color: #ffffff;
  cursor: pointer;
  margin: 0 4px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #6e9c6e;
  }
}
