@import '../../styles/ConstVariable';
.CustomTagVersion-container {
  border: 1px solid #eeeeee;
  border-radius: 32px;
  margin-top: 10px;
  margin-right: 10px;
  width: 95%;
  height: fit-content;
  background-color: $pageBackgroundColor;
  padding: 20px;
  font-family: $pageFontFamily;
  padding-bottom: 70px;
  margin-left: 30px;
  margin-right: 30px;

  .flex-style {
    display: flex;

    .flex-style {
      margin-left: auto;

      button {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
  .versions-button {
    display: flex;
    column-gap: 10px;
    button {
      margin-top: 10px;
      background-color: transparent;
      border: none;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #666666;
    }

    .active-button {
      color: #00580a;
      border-bottom: 3px solid #00580a;
    }
  }

  .straight-line {
    width: 100%;
    border: 1px solid #c5ebc9;
  }
  .current-version-content {
    .title {
      margin-top: 15px;
      margin-bottom: 5px;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height */

      color: #222222;
    }
    .content {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */

      color: #222222;
    }
  }

  .table-title {
    display: grid;
    grid-template-columns: 0.3fr 1fr 1fr 1fr 0.8fr;
    column-gap: 40px;
    font-style: normal;
    padding: 7px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    margin-top: 15px;
    color: #666666;
  }
  .table-content {
    display: grid;
    grid-template-columns: 0.3fr 1fr 1fr 1fr 0.8fr;
    column-gap: 40px;
    align-items: center;
    background: #f9fffb;
    border: 1px solid #c5ebc9;
    border-radius: 12px;
    padding: 7px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */

    color: #222222;

    .tag-name {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #00580a;
    }
    .flex-display {
      display: flex;
      column-gap: 30px;
    }

    .restore-button {
      button {
        border: none;
        background-color: transparent;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height */
        cursor: pointer;
        color: #00580a;
      }
    }
  }
}
