@import '../../styles/ConstVariable';
.ManageUser-container {
  border: 1px solid #eeeeee;
  border-radius: 32px;
  margin-top: 10px;
  margin-right: 30px;
  margin-left: 30px;
  width: 90%;
  height: fit-content;
  background-color: $pageBackgroundColor;
  padding: 20px;
  padding-left: 20px;
  font-family: $pageFontFamily;
  padding-bottom: 70px;

  .subHeading-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #222222;
    margin-bottom: 10px;
  }
  .flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .add-user {
      display: flex;
      width: fit-content;
      background: #c5ebc9;
      border-radius: 12px;
      padding: 6px;

      svg {
        margin-right: 8px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
      }
    }
  }

  .filter-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;

    .flex-display {
      display: flex;
      column-gap: 30px;

      .filter {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        /* identical to box height */

        color: #222222;

        span {
          margin-right: 10px;
        }
      }

      .status {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: #00580a;
        margin-left: 10px;

        span {
          margin-left: 10px;
        }
      }
    }
  }

  .search-box {
    .position-helper {
      position: relative;
    }
    input[type='text'] {
      width: 254px;
      height: 40px;
      background: #fff;
      border: 1px solid #00580a;
      border-radius: 8px;
      padding-left: 10px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
    }

    .search-icon {
      position: absolute;
      top: 13px;
      right: 10px;
    }
  }

  .usersDetails {
    display: grid;
    grid-template-columns: 0.3fr 1fr 1fr 1fr 0.6fr 0.5fr;
    column-gap: 40px;
    font-style: normal;
    padding: 7px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */

    color: #666666;
  }

  .details {
    display: grid;
    grid-template-columns: 0.3fr 1fr 1fr 1fr 0.6fr 0.5fr;
    column-gap: 40px;
    align-items: center;
    background: #f9fffb;
    border: 1px solid #c5ebc9;
    border-radius: 12px;
    padding: 7px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */

    color: #222222;

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
    .flex-display {
      display: flex;
      column-gap: 20px;
    }
  }
}
