@import '../../styles/ConstVariable';

.brandList-container {
  // border: 1px solid #eeeeee;
  // border-radius: 32px;
  margin: 10px;
  width: 95%;
  height: fit-content;
  // background-color: $pageBackgroundColor;
  padding: 20px;
  font-family: $pageFontFamily;
  margin-left: 30px;
  margin-right: 30px;

  .text-container {
    // margin-left: 10px;
  }

  .img {
    // background: rgba(249, 250, 245, 0.5);
    border: 1px solid #c5ebc9;
    border-radius: 8px;
    width: 68px;
    height: 61px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex-style {
    display: flex;

    .flex-style {
      margin-left: auto;

      button {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }

  .headings {
    display: grid;
    grid-template-columns: 0.6fr 0.7fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr 0.4fr;
    column-gap: 20px;
    font-style: normal;
    padding: 10px 0 7px 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #666666;
    margin-top: 15px;
  }

  .lists {
    margin-top: 20px;
  }
}

.button-orange {
  text-align: center;
  font-weight: bold;
  padding: 5px 15px;
  border: 1px solid #fff;
  color: #032a66;
  font-size: 15px;
  text-decoration: none;
  background: #fff;
  border-radius: 10px;
  transition: 0.3s ease;
  box-shadow: 0px 2px 5px 0px #bebebe;
  min-width: 100px;
  margin: 0 !important;
}

.button-orange:hover {
  background: #ffbd45;
  transition: 0.3s ease;
  color: #fff;
  border: 1px solid #ffffff;
}

.align-item-end{
  align-items: flex-end;
}

.max-width254 {
  max-width: 254px;
}

@media only screen and (max-width: 1265px) {
  .BrandList-container {
    .headings {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 20px;
      row-gap: 20px;
      font-style: normal;
      padding: 7px;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height */

      color: #666666;
    }
  }
}
