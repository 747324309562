.personal-tag {
  padding-left: 25px;
  position: relative;
  width: max-content;
}

.personal-tag::before {
  content: '';
  background-image: url('../../../assets/custom-tag.svg');
  width: 19px;
  height: 13px;
  left: 0;
  top: 3px;
  visibility: visible;
  position: absolute;
}

.copyicon_svg {
  position: relative;
  right: -10px;
  width: 15px;
  height: 15px;
}