@import '../../styles/ConstVariable';

.dataSource-container {
  // border: 1px solid #eeeeee;
  // border-radius: 32px;
  margin-top: 10px;
  margin-right: 30px;
  margin-left: 30px;
  width: 95%;
  height: -moz-fit-content;
  height: fit-content;
  // background-color: rgba(249, 250, 245, 0.5);
  padding: 20px;
  font-family: $pageFontFamily;
  padding-bottom: 70px;

  .title {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .subHeading {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 4px;
  }
  .subHeading-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #222222;
    margin-bottom: 10px;
  }

  .label-audience-by {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #222222;
  }
  .input-grid-layout {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: auto 1fr;

    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #222222;
    }

    input[type='radio'] {
      margin: 0;
      margin-right: 4px;
    }

    input[type='radio']:checked {
      accent-color: $linkColor;
    }

    label {
      margin-right: 20px;
    }
  }

  .grid-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;

    // .preview-button {
    //   margin-bottom: 15px;
    //   // .ButtonContainer {
    //     button {
    //       background: transparent;
    //       font-weight: 700;
    //       font-size: 14px;
    //       line-height: 18px;
    //       // border-radius: 12px;
    //       width: fit-content;
    //       padding: 10px;
    //       height: fit-content;
    //       color: #00580a;
    //       cursor: pointer;
    //       border: 1px solid;
    //       width: 160px;
    //     }
    //   // }
    // }
  }

  .upload-section {
    width: 100%;
    height: 345px;

    .border-style {
      height: 100%;
      border: 2px dashed #00580a;
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.05));
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      justify-content: center;
      align-items: center;

      .upload-text {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #222222;
        text-align: center;
      }
    }
  }

  .navigationButtons {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    column-gap: 50px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 980px) {
  .audience-container {
    .grid-layout {
      grid-template-columns: 1fr;
      row-gap: 20px;
    }
  }
}

@media only screen and (max-width: 450px) {
  .audience-container {
    .input-grid-layout {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}
