@import '../../styles/ConstVariable';

.createSystemTemplate-container {
  border: 1px solid #eeeeee;
  border-radius: 32px;
  margin-top: 10px;
  margin-right: 10px;
  width: 95%;
  height: fit-content;
  background-color: $pageBackgroundColor;
  padding: 20px;
  font-family: $pageFontFamily;
  padding-bottom: 70px;
  margin-left: auto;
  margin-right: auto;

  .format{
    padding-bottom: 20px;
  }

  .error-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .error-popup {
      background-color: $cancelButtonBackgroundColor;
      padding: 20px;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      h3 {
        margin: 0 0 10px;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
      }
      li {
        margin-bottom: 5px;
        overflow-wrap: break-word;
        word-break: break-all;
        align-items: center;
      }
      button {
        margin-top: 10px;
        color: red;
      }
    }
  }
  .grid-layout {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 20px;
    margin-top: 10px;
    .css-content,
    .html-content,
    .common-css-content {
      background-color: white;
      padding: 10px;
      padding-right: 20px;
      margin-bottom: 10px;
      border-radius: 10px;
      .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .flex-display {
          display: flex;
          column-gap: 20px;

          .Select-container {
            display: flex;
            column-gap: 10px;
            align-items: center;
            place-content: end;
            margin: 0px;
            .flex-display {
              display: flex;
              justify-content: center;
              align-items: center;
              width: auto !important;
            }
          }
          .html-upload-button {
            display: flex;
            align-items: center;
          }
          .arrow {
            display: flex;
            align-items: center;
          }
        }
        .css-upload-button,
        .html-upload-button,
        .commonCss-upload-button {
          .upload {
            font-size: 16px;
            line-height: 21px;
            cursor: pointer;
            color: #00580a;
          }
        }
      }
      .textarea {
        width: 100%;
        height: 345px;
        // margin-right: 20px;
        margin-bottom: 20px;
        textarea {
          width: 100%;
          height: 345px;
          background: #ffffff;
          border: 1px solid #00580a;
          border-radius: 8px;
          padding: 4px;
        }

        textarea:focus {
          outline: none;
          border: 2px solid #00580a;
        }
      }
    }
  }

  .pdf-button {
    display: block;
    padding: 10px 20px;
    background-color: $cancelButtonBackgroundColor;
    width: fit-content;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: $cancelButtonTextColor;
    border: none;
    border-radius: 10px;
    margin-left: auto;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .navigationButtons {
    margin-top: 20px;
    display: flex;
    column-gap: 50px;
    margin-bottom: 20px;
    justify-content: center;
  }

  // .preview-button {
  //   margin-bottom: 15px;
  //   // .ButtonContainer {
  //     button {
  //       background: transparent;
  //       font-weight: 700;
  //       font-size: 14px;
  //       line-height: 18px;
  //       // border-radius: 12px;
  //       width: fit-content;
  //       padding: 10px;
  //       height: fit-content;
  //       color: #00580a;
  //       cursor: pointer;
  //       border: 1px solid;
  //       width: 160px;
  //     }
  //   // }
  // }
}

@media only screen and (max-width: 940px) {
  .CustomTags-container {
    margin-left: auto;
    margin-right: auto;
    .grid-layout {
      grid-template-columns: 1fr;
    }
  }
}
