.banner {
    top: 0px;
    min-height: 100vh;
    position: relative;
    background: rgb(5 84 141);
    overflow: hidden;
    z-index: 0;
    background: rgb(5 84 141);
    background: linear-gradient(270deg, rgba(7, 169, 122, 1) 0%, rgba(5, 84, 141, 1) 56%);
}

.banner-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    width: 85%;
    gap: 30px;
    justify-content: space-between;
}

.banner-text {
    width: 50%;
    text-align: left;
}

.banner-hero {
    position: relative;
    top: 40px;
}

.banner-hero img {
    /*    position: absolute;*/
    width: 620px;
    left: -80px;
    top: -20px;
}

.banner-content h1 {
    font-size: 64px;
    line-height: 80px;
    font-weight: 700;
    margin-bottom: 40px;
}

.banner-content p {
    font-size: 19px;
    line-height: 28px;
    font-weight: 300;
}

.banner-text a {
    color: #fff;
    text-decoration: underline;
}