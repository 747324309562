@import '../../styles/ConstVariable';

.upload-container{
    width: fit-content;

    button{
        background: $cancelButtonBackgroundColor;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        border-radius: 12px;
        width: 133px;
        height: 43px;
        color: $cancelButtonTextColor;
        cursor: pointer;
        border: none;

        span{
            margin-right: 5px;
        }
    }
        
}