@import '../../styles/ConstVariable';

.pdf-design-container {
  background-color: white;
  padding: 30px;
}

.draggable {
  cursor: move;
}

.draggable.dragging {
  opacity: 0.5;
}

.full-height-pdf-design {
  min-height: 100vh;
  height: 100%;
}

.full-height-pdf-design .cm-tag-design-container {}

.full-height-pdf-design .cm-tag-design-container .cm-tag-design-section {
  height: 1123px;
  overflow: hidden;
}