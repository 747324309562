@import '../../styles/ConstVariable';

.breadcrum-container{
    display: flex;
    font-size: 14px;
    line-height: 19px;
    font-family: Aver Bold, sans-serif;
    margin: 5px 5px 15px 0;

    .previousPath{
        font-weight: 700;
        color: $linkColor;

        .greaterthan{
            font-weight: 600;
            color: #666666;
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    .currentpath{
            font-weight: 600;
            color: #666666;
    }
}