@import '../../styles/ConstVariable';

.createCampaign-container {
  margin-top: 10px;
  margin-right: 30px;
  margin-left: 30px;
  width: 95%;
  height: fit-content;
  padding: 20px;
  font-family: $pageFontFamily;
  padding-bottom: 70px;

  .grid-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;

    .label-text {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #222222;
      display: block;
      margin-bottom: 5px;
    }
  }
  .subHeading {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 4px;
  }
  .subHeading-texts {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #222222;
    margin-bottom: 10px;
  }

  .navigationButtons {
    display: flex;
    column-gap: 50px;
    margin-bottom: 20px;
    justify-content: space-between;
    margin-top: 60px;
    width: calc(50% - 10px);
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .selectbox-subLabel {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #222222;
  }
}

.createCampaign-form {
  padding: 0 50px;
}
.right-image-panel {
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-image-panel img {
  max-width: 200px;
  opacity: 0.6;
}
// .Select-container div {
//   max-width: 100%;
// }

@media only screen and (max-width: 940px) {
  .createCampaign-container {
    margin-left: auto;
    margin-right: auto;
    .grid-layout {
      grid-template-columns: 1fr;
    }
  }
}
