.cm-br-empty-class::before {
    content: 'empty line';
    font-size: 12px;
    font-weight: normal;
    color: #B4B4B4;
    padding-left: 10px;
    left: 10px;
}
.cm-br-empty-class {
    border: 1px dashed #eee;
}