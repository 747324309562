.social-component-container {
    max-width: 500px;
    height: 500px;
    background-color: #eee;
    border: 20px solid #237c91;
    margin: 0 auto;
  }
  .social-template-content {
    margin: 25px;
    max-height: -webkit-fill-available;
    height: 100%;
    position: relative;
  }
  .social-template-content * {
    border: 2px solid transparent;
  }
  .social-template-content > *:hover,
  .social-template-content > *:focus {
    border: 2px solid #8b3dff;
  }
  .social-template-content input {
    font-family: "Playfair Display", serif;
    background-color: transparent;
    width: 100%;
  }
  .social-template-content input:focus,
  .social-template-title textarea:focus {
    outline: none;
  }
  .social-template-title textarea {
    font-size: 4.5em;
    line-height: 1em;
    font-weight: bold;
    color: #237c91;
    max-width: -webkit-fill-available;
    font-family: "Playfair Display", serif;
    background-color: transparent;
    overflow: hidden;
    resize: none;
  }
  .social-template-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  p {
    margin: 0;
  }
