.custom-select {
  position: sticky;
  top: 0;
  max-width: 100%;
  background-color: #f5f5f5;
  height: 100%;
  padding: 12px;
  border-radius: 8px;
  font-size: 12px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  .toggle-button {
    cursor: pointer;
  }
}

.section-content {
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .content-label {
    display: flex;
    align-items: center;
  }

  .input-select-container {
    display: inline;

    select {
      margin-left: 8px;
    }
  }

  .span-content {
    padding: 8px 30px;
    font-size: 12px;
    border-radius: 8px;
    background-color: #eee;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 0px 5px;
  align-items: center;
  padding: 0px 15px;
}

.bottom-border {
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
}
