.search-template {
  display: flex;
  align-items: center;
  gap: 15px;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

.search-template input {
  padding: 10px 15px;
  color: #07548b;
  border: 0;
  background-color: #ededed;
  border-radius: 5px;
  max-width: 530px;
  width: 100%;
  height: 50px;
  font-size: 16px;
}

.search-template-btn {
  background-color: #06a87a;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.search-template-btn:hover {
  background-color: #06805d;
}

.pdf-template-wrapper {
  margin: 30px 0 100px;
}

.templates-container {
  display: flex;
  column-gap: 25px;
  row-gap: 50px;
  flex-wrap: wrap;
}

.template-box {
  max-width: 400px;
  width: 31.3333%;
}

.template-preview-image img {
  width: 100%;
  height: 220px;
}

.template-box h2 {
  font-size: 18px;
  font-weight: 500;
}

.template-box p {
  font-size: 14px;
  height: 60px;
  margin-bottom: 0;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.template-box .use-template-cta-wrapper .use-template-btn {
  border: 1px solid #044371;
  padding: 10px;
  width: 100%;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  margin-top: 20px;
  text-decoration: none;
  color: #044371;
}

.template-box .use-template-cta-wrapper .use-template-btn:hover {
  background-color: #044371;
  color: #fff;
}

/*---------------------------------------------*/

.primary {
  min-height: 800px;
}

/*** Accordion Toggles ***/
.panel-heading {
  position: relative;
}

// .panel-heading .accordion-toggle:after {
//   font-family: 'Glyphicons Halflings';
//   content: "\e260";
//   position: absolute;
//   right: 16px;
// }
// .panel-heading .accordion-toggle.collapsed:after {
//   font-family: 'Glyphicons Halflings';
//   content: "\e259";
// }
/*** Filter Menu ***/
/* Panels */

.filter-menu {
  border: 1px solid #acacac;
}

.filter-menu .panel {
  border-radius: 0;
  border: 0;
  width: 180px;
  margin-bottom: 1px;
}

.filter-menu .panel-heading {
  background: #eee;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.filter-menu .panel-title {
  color: #333333;
  display: block;
  padding: 16px;
}

.filter-menu a.panel-title {
  color: #333333;
  background-color: #eee;
}
.filter-menu .panel-body > .panel-title{
  padding: 0;
  text-align: left;
}

.filter-menu a.panel-title:hover,
.filter-menu a.panel-title:focus {
  color: #333333;
  text-decoration: none;
}

.filter-menu .panel-body {
  padding: 16px;
  background-color: aliceblue;
}

/* Inner Panels */
.filter-menu .panel-group {
  margin: -16px;
}

.filter-menu .panel-group .panel-title {
  background: #eee;
  transition: color, 0.5s, ease;
  cursor: pointer;
}

/*.filter-menu .panel-group .panel-title:hover {
  color: #333333;
  text-decoration: none;
  background: #777777;
}*/
.filter-menu .panel-group .panel+.panel {
  margin-top: 0;
}

/*** Filter Menu - Mobile ***/
/* Panels - Mobile */
.filter-menu.mobile .btn-link {
  color: #f9f9f9;
}

.filter-menu.mobile hr {
  margin-top: 0;
  border-top-color: #4b6473;
}

.filter-menu.mobile .panel-group .panel-heading+.panel-collapse>.panel-body {
  border-color: #4b6473;
}

.filter-menu.mobile .panel {
  border-color: #4b6473;
  background: #30404a;
  color: #f9f9f9;
}

.filter-menu.mobile .panel-heading {
  background: #30404a;
}

.filter-menu.mobile a.panel-title {
  color: #f9f9f9;
}

.filter-menu.mobile a.panel-title:hover {
  color: #f9f9f9;
}

.filter-menu.mobile .panel-group .panel {
  border-color: #4b6473;
}

.filter-menu.mobile .panel-group .panel-title {
  background: #3f5460;
}

.filter-menu.mobile .panel-group .panel-title:hover {
  color: #f9f9f9;
  background: #30404a;
}

.panel-body .checkbox:hover {
  background-color: aliceblue;
}

.panel-body .checkbox {
  padding: 6px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.panel-body .checkbox label {
  user-select: none;
  display: flex;
  align-items: center;
  font-weight: normal;
}
.panel-body .checkbox label input {
  margin-right: 6px;
}
.panel-body .checkbox .template-counts {
  background-color: aliceblue;
  padding: 5px;
  line-height: 1;
  border-radius: 4px;
}

.expand-icon {
  cursor: pointer;
  position: absolute;
  right: 18px;
}

@media screen and (max-width: 768px) {
  .left-template-filter {
    display: none;
  }

  .template-box {
    max-width: 300px;
    width: 100%;
  }

  .templates-container {
    justify-content: center;
  }
}