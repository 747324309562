@import './styles/ConstVariable';

p,
ul,
li,
h4,
h1,
h2,
h3 {
  margin: 0px;
  padding: 0px;
}

body {
  font-size: $textFont;
  font-family: $pageFontFamily;
  scrollbar-width: thin;
  overflow-x: hidden;
  min-height: 100vh;
  min-width: 100vw;
  background-repeat: no-repeat;
  background-attachment: fixed;
  box-sizing: border-box;
  margin: 0;
  padding-bottom: 20px;
}

a {
  text-decoration: none;
}

.App {
  min-height: 100vh;
  min-width: 100vw;
  color: #666666;
  // background: $bodyBackgroundColor;
}
