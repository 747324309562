@import '../../styles/ConstVariable';


.ButtonContainer{
    width: fit-content;
    button{
        background: $cancelButtonBackgroundColor;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        border-radius: 12px;
        width: fit-content;
        padding-left: 20px;
        padding-right: 20px;
        height: 43px;
        color: $cancelButtonTextColor;
        cursor: pointer;
        border: none;

    }
   
}

.CreateButtonContainer{
    width: fit-content;
    button{
        background: $nextButtonBackgroundColor;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        border-radius: 12px;
        width: fit-content;
        padding-left: 20px;
        padding-right: 20px;
        height: 43px;
        color: white;;
        cursor: pointer;
        border: none;

    }

}