@import '../../styles/ConstVariable';

.brandBox-container{
    font-family:$pageFontFamily;
    background: #F9FFFB;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
    flex-direction: column; 
    column-gap: 5px;
    width:225px;
    height: 170px;
    margin-top: 5px;
    position: relative;
    cursor: pointer;

    img{
        margin-top: 5px;
        margin-bottom: 25px;
    }

    .brand-name{
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        text-align: center;

        color: #00580A;
    }


    .noOfCampaigns{
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        // text-align: center;
        color: #666666;
    }
}


.menu{
    background: #D8F2DB;
    border-radius: 4px;
    width: fit-content;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 1px;
    font-size: 10px;
    position: absolute;
    top:7px;
    right: 7px;
    
}