.gp-pdf-preview-table tr:hover {
  background-color: transparent;
}

.gp-pdf-preview-table th,
.gp-pdf-preview-table td {
  border: 0;
  padding: 0px;
  text-align: left;
}

.gp-pdf-preview-table tr>td:first-child {
  padding-right: 12px;
}

.gp-pdf-preview-table tr:nth-child(even) {
  background-color: transparent;
}

.fwg-page-content-pad40 {
  padding: 10px 40px;
}

.fwg-large-title {
  font-size: 50px;
  font-family: 'Roboto Condensed', sans-serif;
}

.fwg-logo-blue {
  color: #2cabe0;
}

.fwg-settlement-table {
  border: 1px solid #cae5f6;
  border-radius: 8px;
}

// table.fwg-settlement-table tr:nth-child(2) td {
//   border-top: 1px solid #cae5f6;
//   border-bottom: 1px solid #cae5f6;
// }

table.fwg-settlement-table tr td {
  border-bottom: 1px solid #cae5f6;
}

table.fwg-settlement-table tr:last-child td {
  border-bottom: 0;
}

table.fwg-settlement-table tr td:nth-child(1) {
  font-family: 'Roboto Condensed', sans-serif;
}

.fwg-settlement-table tr td {
  height: 30px;
  vertical-align: middle;
  padding: 0 5px;
  line-height: 1;
}

.fwg-settlement-flex {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
}

td.fwg-s-title {
  font-size: 11px;
}

td.fwg-s-value {
  width: 170px;
  font-size: 13px;
}