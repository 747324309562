.cm-pagination {
      display: flex;
    cursor: pointer;
    justify-content: end;
    margin-right: 20px;

  li {
    color: black;
    // float: left;
    padding: 4px 8px;
    text-decoration: none;
    list-style: none;
    border-radius: 10px;
  }
  li.active {
    background-color: #4caf50;
    color: white;
  }
  li:hover:not(.active) {
    background-color: #ddd;
  }
}
