.fwg-logo-block {
  position: relative;
  margin-bottom: 20px;
}
.fwg-logo-block svg {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  padding: 0;
  z-index: 1;
}
.fwg-logo-block span.fwg-blue-line {
  height: 5px;
  display: block;
  background-color: rgb(44 171 224);
  position: relative;
  top: 21px;
}

.fwg-svg {
  enable-background: new 0 0 80 30.3;
}

.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #1cb580;
}
.st2 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #2ab24a;
}
.st3 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #1a57a7;
}
.st4 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #2cabe1;
}
