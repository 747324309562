		.fwg-page-content-pad40 {
			padding: 10px 40px;
		}

		.fwg-large-title {
			font-size: 50px;
			font-family: 'Roboto Condensed', sans-serif;
		}

		.fwg-logo-blue {
			color: #2cabe0;
		}

		.ncs-table {
			border: 1px solid #CAE5F6;
			border-radius: 8px;
		}

		// table.ncs-table tr:nth-child(2) td {
		//     border-top: 1px solid #CAE5F6;
		//     border-bottom: 1px solid #CAE5F6;
		// }

		table.ncs-table tr td {
			border-bottom: 1px solid #cae5f6;
		}

		table.ncs-table tr:last-child td {
			border-bottom: 0;
		}

		table.ncs-table tr td:nth-child(1) {
			font-family: 'Roboto Condensed', sans-serif;
		}

		.ncs-table tr td {
			height: 30px;
			vertical-align: middle;
			padding: 0 5px;
			line-height: 1;
		}

		.fwg-settlement-flex {
			display: flex;
			gap: 30px;
			margin-bottom: 30px;
		}

		td.fwg-s-title {
			font-size: 11px;
		}

		td.fwg-s-value {
			width: 170px;
			font-size: 13px;
		}