.Select-container {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: normal;

  .css-1f43avz-a11yText-A11yText {
    min-height: 200px;
  }
  .flex-display {
    display: flex;
    justify-content: space-between;
    align-items: end;

    label {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #222222;
      display: block;
      margin-bottom: 5px;
    }
  }
}
