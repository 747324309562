@import '../../styles/ConstVariable';

.cm-inputbox-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  input {
    background: #ffffff;
    border: 1px solid $inputBorderColor;
    font-family: $pageFontFamily;
    border-radius: 8px;
    //width: 100%;
    height: 40px;
    color: black;
    padding-left: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }
  .flex-display {
    display: flex;
    justify-content: space-between;
    align-items: end;
    label {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: #222222;
      display: block;
      margin-bottom: 5px;
    }
  }
}
