@import '../../styles/ConstVariable';

.ReviewHtmlPreview-container {
  // border: 1px solid #eeeeee;
  // border-radius: 32px;
  margin-top: 10px;
  margin-right: 30px;
  margin-left: 30px;
  width: 95%;
  height: fit-content;
  // background-color: $pageBackgroundColor;
  padding: 20px;
  font-family: $pageFontFamily;
  padding-bottom: 70px;
  padding-bottom: 20px;
  height: max-content;

  .subHeading {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 4px;
  }

  .subHeading-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #222222;
    margin-bottom: 10px;
  }

  .navigationButtons {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    column-gap: 50px;
    margin-bottom: 20px;
  }

  .pdf-rev-cont-width {
    max-width: fit-content;
    margin: 0 auto;
  }

  .react-pdf__Document {
    margin-top: 10px;
  }
  
}