.cm-preview-container-form {
  background-color: #fff;
  padding: 20px;
  height: 100%;
  width: -webkit-fill-available;
  form .properties-wrapper {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    height: 560px;
    overflow-y: scroll;
  }
  // form {
  //   border: 1px solid #d7d7d7;
  // }
}
.width-370 {
  width: 370px;
}
.width-100p {
  width: 100%;
}
.cm-preview-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 20px;
}
.cm-form-elements {
  margin-bottom: 15px;
}
.cm-form-elements label {
  font-size: 12px;
  color: #0e0e0e;
  display: block;
  margin-bottom: 5px;
}
.cm-form-elements .cm-input-elements input {
  width: 100%;
  width: -webkit-fill-available;
  padding: 10px;
  background-color: #f3f3f3;
  border: 1px solid #dadada;
}
.cm-form-elements .cm-input-elements select {
  width: 100%;
  width: -webkit-fill-available;
  padding: 10px;
  background-color: #f3f3f3;
  border: 1px solid #dadada;
}
.cm-form-elements .cm-input-elements input:focus,
.cm-form-elements .cm-input-elements select:focus {
  outline: none;
}
.cm-preview-buttons {
  max-width: 100%;
  /* margin: 0 auto; */
  display: flex;
  justify-content: end;
  /* padding-bottom: 20px; */
}
.cm-preview-buttons button {
  background-color: #f3f3f3;
  border: 1px solid #c5c5c5;
  width: 105px;
  padding: 7px 15px;
  margin: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: start;
  font-size: 12px;
}
.cm-preview-buttons button span svg {
  width: 20px;
  margin-right: 10px;
}
button#desktop-view {
  border-right: 0;
  border-radius: 5px 0 0 5px;
}
button#mobile-view {
  border-radius: 0 5px 5px 0;
}
.cm-preview-buttons button:hover {
  background-color: #ffffff;
}
.cm-preview-buttons button.active {
  background-color: #d5d5d5;
}
/*	Pagination	*/

.cm-pagination,
.page-numbers {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.btn-nav,
.btn-page {
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

.btn-nav {
  padding: 8px;
}

.btn-nav {
  width: 32px;
  height: 32px;
  border: 1.5px solid #087f5b;
  color: #087f5b;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-nav:hover,
.btn-page:hover {
  background-color: #c2c2c2;
  color: #fff;
}

.btn-page {
  border: none;
  width: 32px;
  height: 32px;
  font-size: 16px;
}

.btn-selected {
  background-color: #e8e8e8;
  color: #333;
}
.btn-selected:hover {
  background-color: #e8e8e8;
  color: #333;
}
.cm-pagination-wrapper {
  /* display: flex; */
  /* justify-content: flex-end; */
  /* margin-top: 30px; */
  position: relative;
  top: 20px;
}
.cm-pagination {
  // margin-top: 15px;
}
/*pagination ends*/
/*scroll bar*/
@media screen and (max-width: 590px) {
  form .properties-wrapper {
    max-width: 360px;
    margin: 0 auto;
    padding: 20px;
  }
}

.add-remove-buttons-container {
  display: flex;
  /* border: 1px solid #c5c5c5; */
  border-radius: 6px;

  button {
    display: flex;
    align-items: center;
    border: none;
    width: 130px;
    align-items: center;
    justify-content: center;
    padding: 7px;
    cursor: pointer;
    span {
      display: flex;
      svg {
        width: 24px;
        color: #fff;
        fill: #333;
        margin-right: 4px;
      }
    }
  }
}
button#delete-page {
  background-color: #d5d5d5;
  color: #464646;
  border: 1px solid #c5c5c5;
  border-radius: 0px 5px 5px 0px;
  
}

button#delete-page:hover {
  border: 1px solid #333333;
}

button#add-page {
  background-color: #f3f3f3;
  color: #464646;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #c5c5c5;
  
}

button#add-page:hover {
  border: 1px solid #333333;
}
