@import '../../styles/ConstVariable';

.textbox-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  textarea::placeholder {
    color: #9db29f;
    padding-left: 5px;
  }

  textarea {
    background: #ffffff;
    border: 1px solid $inputBorderColor;
    border-radius: 8px;
    //width: 100%;
    padding-left: 5px;
    padding-top: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    font-family: $pageFontFamily;
  }
  .flex-display {
    display: flex;
    justify-content: space-between;
    align-items: end;
    label {
      font-weight: 700;
      color: #222222;
      display: block;
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 19px;
    }
  }
}
