.pad-tb80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.contact-info-block h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    color: #333;
}

.contactinfo-container {
    padding: 0 90px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.contactus-container h1 {
    font-size: 36px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
    margin: 0.67em 0;
}

.breadcrum-title h1 {
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.contactus-container .text-center {
    text-align: center;
}

.contact-info-block p {
    margin: 0 0 10px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
}

.contact.banner {
    min-height: 60vh;
}

.contact-bg {
    background-color: aliceblue;
}

.contact-form {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 100px;
}

.contact-hero-img {
    max-width: 550px;
    width: 100%;
}

.contact-form form {
    max-width: 40%;
    width: 100%;
}

.contact-form .input-element {
    margin-bottom: 16px;
    /*    max-width: 400px;*/
}

.contact-form .input-element input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #c8c8c8;
}

.contact-form .input-element textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #c8c8c8;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.contact-form .input-element label {
    font-weight: 500;
}

.contact-img img {
    max-width: 60px;
    width: 100%;
}

.contact-info-block {
    display: flex;
    align-items: center;
    column-gap: 30px;
}

.contact-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contact-map {
    margin-top: 70px;
}

button.contact-submit-btn {
    background-color: #05548d;
    border: navajowhite;
    padding: 10px;
    width: 100%;
    border-radius: 50px;
    color: #fff;
}

/*-------------------------- Hover ---------------------*/

/*-------------------------- Hover ---------------------*/
@media only screen and (min-width: 768px) and (hover: hover) {
    li.sub-nav:hover ul {
        display: block;
        height: max-content;
        transition: 0.3s ease;
        overflow-y: auto;
        list-style: none;
        padding-left: 0;
        border: 1px solid #eee;
    }

    /*.nav > .nav-links > ul > li > a:hover {
          background-color: rgba(0, 0, 0, 0.3);
      }*/
    .nav>.nav-links>ul>li:hover a span i {
        transform: rotate(180deg);
        transition: 0.3s ease;
    }

    ul.submenu>li:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

/*-------------------------- Mobile ---------------------*/
@media (max-width: 768px) {
    .contactus-container {
        padding: 0 20px;
    }

    .logo .white-logo {
        display: none;
    }

    .logo .default-logo {
        display: block;
    }

    .logo img {
        max-width: 50%;
    }

    .nav {
        height: 50px;
        padding: 0 20px;
        background-color: #ededed;
        justify-content: left;
    }

    .nav>.nav-btn {
        display: inline-block;
        position: absolute;
        right: 0px;
        top: 0px;
    }

    .nav>.nav-btn>label {
        display: inline-block;
        width: 50px;
        height: 50px;
        padding: 13px;
    }

    /*.nav > .nav-btn > label:hover,.nav  #nav-check:checked ~ .nav-btn > label {
          background-color: rgba(0, 0, 0, 0.3);
      }*/
    .nav>.nav-btn>label>span {
        display: block;
        width: 25px;
        height: 10px;
        border-top: 2px solid #333;
    }

    .nav>.nav-links {
        position: absolute;
        display: block;
        width: 100%;
        background-color: #ededed;
        height: 0px;
        transition: all 0.3s ease-in;
        overflow-y: hidden;
        top: 50px;
        left: 0px;
    }

    .nav>.nav-links>ul {
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
    }

    .nav>.nav-links ul li {
        width: 100%;
    }

    .nav>.nav-links>ul>li a span i {
        transition: all 0.3s ease;
    }

    .nav>.nav-links>ul>li>a {
        display: block;
        width: 100%;
        color: #333;
    }

    .nav>#nav-check:not(:checked)~.nav-links {
        height: 0px;
    }

    .nav>#nav-check:checked~.nav-links {
        height: calc(100vh - 50px);
        overflow-y: auto;
        padding: 0 20px;
    }

    li.sub-nav input[type='checkbox'] {
        display: block;
    }

    li.sub-nav input[type='checkbox']:checked~ul {
        height: 100%;
        position: relative;
        display: block;
        list-style: none;
        padding: 0;
    }

    li.sub-nav input[type='checkbox'] {
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        height: 50px;
        opacity: 0;
    }

    li.sub-nav input[type='checkbox']:not(:checked)~ul.submenu {
        transition: all 0.5s ease-out;
        top: -100px;
        overflow-y: hidden;
    }

    li.sub-nav input[type='checkbox']:checked~ul.submenu {
        transition: all 0.5s ease-out;
        top: 0;
        overflow-y: auto;
    }

    a.header-contact-btn {
        margin-top: 20px;
    }

    .banner-content {
        flex-direction: column;
        width: 100%;
        padding: 0 20px;
        gap: 0px;
    }

    .banner-text {
        width: 100%;
        text-align: left;
    }

    .banner-content h1 {
        font-size: 28px;
        line-height: normal;
    }

    .banner-content p {
        font-size: 16px;
        line-height: 24px;
    }

    .banner-hero iframe {
        width: 100%;
        height: 190px;
    }

    .banner-hero img {
        display: none;
    }

    .pad-t0-mbl {
        padding-top: 0;
    }

    .policy-bg-para {
        width: 100%;
    }
}