@import '../../styles/ConstVariable';

.campaign-html-preview-container {
  border: 0;
  border-radius: 0;
  /* margin-top: 0px; */
  margin: 0;
  /* margin-right: 20px; */
  width: 95%;
  height: -moz-fit-content;
  height: fit-content;
  background-color: transparent;
  padding-top: 10px;
  /* padding-left: 15px; */
  font-family: Aver Bold, sans-serif;
  padding-bottom: 50px;

  .title {
    margin-top: 5px;
    margin-bottom: 20px;
    // font-size: 25px;
  }

  .subHeading {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 4px;
  }
  .subHeading-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #222222;
    margin-bottom: 10px;
  }

  .outer-grid-layout-1 {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 20px;
    row-gap: 20px;
  }
  .outer-grid-layout-2 {
    display: grid;
    grid-template-columns: 4fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
  }
  .outer-grid-layout-1-reverse {
    display: flex;
    flex-direction: column-reverse;
    column-gap: 20px;
    row-gap: 20px;
  }

  .outer-grid-layout-1-reverse,
  .outer-grid-layout-2,
  .outer-grid-layout-1 {
    .html-code-section {
      .multiple-html-box-style {
        margin-top: 15px;
        background-color: white;
        // padding-top: 10px;
        padding-left: 10px;
        padding-right: 20px;

        border-radius: 20px;
        margin-bottom: 10px;

        .headings {
          display: flex;
          background-color: white;
          border-radius: 25px;
          justify-content: space-between;
          padding: 10px;
          cursor: pointer;
        }

        .textbox-info {
          //   display: grid;
          //   grid-template-columns: 1fr 1fr 1fr;
          //   column-gap: 30px;
          //   align-items: center;
          //   margin-bottom: 10px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 30px;
          align-items: center;

          .upload {
            font-size: 14px;
            line-height: 21px;
            cursor: pointer;
            color: #00580a;
            margin-bottom: 10px;
            font-weight: 100;
          }

          .inputbox-container {
            width: 150px;
            display: flex;
            column-gap: 20px;
            align-items: center;
          }

          .Select-container {
            display: flex;
            column-gap: 10px;
            align-items: center;
            place-content: end;

            .flex-display {
              display: flex;
              justify-content: center;
              align-items: center;
              width: auto !important;
            }
          }
        }

        .textarea {
          width: 100%;
          height: 345px;
          margin-right: 20px;

          textarea {
            width: 99%;
            height: 100%;
            background: #ffffff;
            border: 1px solid #00580a;
            border-radius: 8px;
            padding: 4px;
          }
        }

        textarea:focus {
          outline: none;
          border: 2px solid #00580a;
        }

        .collapsed {
          .hide,
          .textarea,
          .css-upload-button,
          .uploadSection {
            display: none;
          }
        }

        .toggle-icon {
          cursor: pointer;
          margin-left: 8px;
        }

        .toggle-icon span {
          display: inline-block;
          //   transform: rotate(90deg);
          transition: transform 0.8s ease;
        }

        .html-box.collapsed .toggle-icon span {
          transform: rotate(0deg);
        }
      }
      .grid-layout2 {
        display: grid;
        grid-template-columns: max-content auto;
        column-gap: 20px;

        .width-300px {
          width: 300px;
        }

        .html-box {
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          .hide {
            display: none;
          }
          .headings {
            display: flex;
            justify-content: space-between;
            .css-upload-button {
              font-size: 14px;
              line-height: 21px;
              cursor: pointer;
              color: #00580a;
              margin-bottom: 10px;
              margin-left: auto;
              margin-right: 10px;
              display: flex;
              justify-content: end;
            }

            .upload {
              font-size: 14px;
              line-height: 21px;
              cursor: pointer;
              color: #00580a;
            }
          }
          .textarea {
            width: 100%;
            height: 345px;
            margin-right: 20px;
            textarea {
              width: 100%;
              height: 345px;
              background: #ffffff;
              border: 1px solid #00580a;
              border-radius: 8px;
              padding: 4px;
            }

            textarea:focus {
              outline: none;
              border: 2px solid #00580a;
            }
          }
        }
      }
    }
  }

  .html-preview-section {
    .pdf-button {
      display: block;
      padding: 10px 20px;
      background-color: $cancelButtonBackgroundColor;
      width: fit-content;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      color: $cancelButtonTextColor;
      border: none;
      border-radius: 10px;
      margin-left: auto;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }

  .padding-b-20px {
    padding-bottom: 20px;
  }

  .padding-b-10px {
    padding-bottom: 10px;
  }

  .padding-r-20px {
    padding-right: 20px;
  }
  .add-more-button {
    display: block;
    margin-top: 20px;
  }
  .navigationButtons {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    column-gap: 50px;
    margin-bottom: 20px;
  }

  .pdfPreview-pop-up {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0px);

    .position-helper {
      position: relative;
      .closePfdPreview {
        position: absolute;
        top: 1px;
        right: 10px;
        color: green;
        cursor: pointer;
        font-size: 16px;
        font-weight: 800;
      }
    }
  }
  .error-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .error-popup {
      background-color: $cancelButtonBackgroundColor;
      padding: 20px;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      h3 {
        margin: 0 0 10px;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
      }
      li {
        margin-bottom: 5px;
        overflow-wrap: break-word;
        word-break: break-all;
        align-items: center;
      }
      button {
        margin-top: 10px;
        color: red;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .CampaignHtmlPreview-container {
    .outer-grid-layout-2 {
      grid-template-columns: 1fr;
      row-gap: 30px;

      .html-code-section {
        .grid-layout2 {
          display: grid;
          grid-template-columns: auto 1fr;
        }
      }
    }
  }
}

@media only screen and (min-width: 1350px) {
  .CampaignHtmlPreview-container {
    .multiple-html-box-style {
      .textbox-info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        align-items: center;

        // div:last-child {
        //   margin-left: auto;
        //   margin-right: auto;
        // }
      }
    }
  }
}

// @media only screen and (max-width: 1350px) {
//   .CampaignHtmlPreview-container {
//     .multiple-html-box-style {
//       .textbox-info {
//         display: grid;
//         grid-template-columns: 1fr 1fr;
//         column-gap: 30px;
//         align-items: center;
//       }
//     }
//   }
// }

@media only screen and (max-width: 1206px) {
  .CampaignHtmlPreview-container {
    .multiple-html-box-style {
      .textbox-info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;
        align-items: center;
      }
    }
  }
}
