@import '../../styles/ConstVariable';


.brands-container{
    // border: 1px solid #EEEEEE;
    // border-radius: 32px;
    margin: 10px;
    width: 95%;
    height:fit-content;
    // background-color:  $pageBackgroundColor;
    padding: 20px;
    font-family: $pageFontFamily;
    padding-bottom: 70px;
    height: 65vh;
    margin-right: 30px;
    margin-left: 30px;

    .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .heading{
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        color: #222222;

        .subheading{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
        }
    }

    .create-brand{
    text-align: center;
    line-height: 30px;
    padding: 5px 15px;
    border: 1px solid #fff;
    color: #032a66;
    font-size: 15px;
    text-decoration: none;
    background: #fff;
    border-radius: 10px;
    transition: 0.3s ease;
    box-shadow: 0px 2px 5px 0px #bebebe;
    }

    .brand-list{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .create-brand:hover {
        background: #ffbd45;
        transition: 0.3s ease;
        color: #fff;
        border: 1px solid #ffffff;
    }
}