// $color: blue;
// $borderColor: rgb(192, 156, 89);

// $textFont: 16px;
// $loginPageTextColor: gray;
// $buttonBackgroundColor: orange;
// $buttonTextColor: white;

// $folderTextColor: #626262;
// $leftPanelBorderColor: lightgray;

// $imageTextColor: #ae2e6c;
// $imageTextHoverColor: rgb(133, 19, 133);

// campain manager colours

// $bodyBackgroundColor: linear-gradient(#c7ffe5, rgba(242, 255, 249, 0.5));

$textFont: 16px;
$buttonBackgroundColor: orange;
$pageBackgroundColor: rgba(249, 250, 245, 0.5);

$pageFontFamily: Aver Bold, sans-serif;

$nextButtonBackgroundColor: #00580a;

$cancelButtonBackgroundColor: #c5ebc9;
$cancelButtonTextColor: #00580a;

$loginButtonBackgroundColor: #00580a;
$loginButtonTextColor: white;

$previousButtonBackground: #c5ebc9;
$previousButtonTextColor: #00580a;

$linkColor: #00580a;
$inputBorderColor: #00580a;

$stepperCircleBackgroundColor: #e6fff4;
$steperCircleTextColor: #222;
$stepperCircleBackgroundColorActive: #c5ebc9;
$steperCircleActiveTextColor: #fff;
$stepperBorderColor: #c5ebc9;

$editDeleteButtonTextColor: #00580a;
$editDeleteButtonBackgroundColor: #c5ebc9;
