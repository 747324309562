@import '../../../styles/ConstVariable';

.campaignprogress-container {
  height: -moz-fit-content;
  height: fit-content;
  padding: 20px;
  font-family: Aver Bold, sans-serif;
  margin-right: 30px;
  margin-left: 30px;

  .headings {
    display: grid;
    grid-template-columns: 0.5fr 0.6fr 1fr 0.6fr 0.6fr 0.6fr 0.6fr;
    column-gap: 30px;
    font-style: normal;
    padding: 7px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */
    color: #666666;
  }

  .text-container {
    margin-left: 0px;
    margin-top: 10px;
  }

  .flex-style {
    display: flex;

    .flex-style {
      margin-left: auto;
      margin-top: auto;

      button {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }

  .nav-container {
    display: flex;
    margin-top: 30px;

    .active {
      margin-right: 10px;
      margin-left: 5px;
      border: none;
    }

    .archived {
      margin-right: 40px;
      margin-left: 20px;
      border: none;
    }

    .active:active {
      color: blue;
    }
  }

  .list {
    display: block;
    margin-top: 15px;
    padding-left: 5px;
  }

  .subheading {
    display: flex;
    margin-top: 30px;
    padding-right: 0px;
    margin-left: 7px;

    .Name {
      margin-right: 10px;
      margin-left: 5px;
    }

    .LastExecuted {
      margin-right: auto;
      margin-left: auto;
    }

    .LastPublicCount {
      margin-left: auto;
      margin-right: auto;
    }

    .lastfailcount {
      margin-left: auto;
      margin-right: auto;
    }

    .totalpublish {
      margin-left: auto;
      margin-right: auto;
    }

    .recurringtype {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
