@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
* {
  box-sizing: border-box;
  outline: none;
}
html {
  scroll-behavior: smooth;
}
body {
  padding: 0;
  margin: 0;
  font-family: 'Epilogue', sans-serif;
  color: #333;
  position: relative;
}
.bebas-font {
  font-family: 'Bebas Neue', sans-serif;
}
.container {
  padding: 0 90px;
  width: 100%;
  background: none;
  position: initial;
  border-radius: 0px;
}
.row {
  display: flex;
}
.justify-content-space-between {
  justify-content: space-between;
}
.text-center {
  text-align: center;
}
.color-white {
  color: #ffffff;
}
.pad-tb80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
.marg-t0 {
  margin-top: 0 !important;
}
.marg-b0 {
  margin-bottom: 0 !important;
}
.logo {
  position: relative;
  top: 2px;
}
.logo .default-logo {
  display: none;
}
header.active {
  top: 0;
  background-color: #ededed;
  transition: 0.3s ease;
  position: fixed;
  z-index: 9;
}
header.active .logo .white-logo {
  display: none;
}
header.active .logo .default-logo {
  display: block;
}
/*navigation*/
/*.nav {
	height: auto;
	width: 100%;
	padding: 5px 80px;
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.nav > .nav-header {
    display: flex;
}
.nav > .nav-header > .nav-title {
	display: inline-block;
	font-size: 22px;
	color: #fff;
	padding: 10px 10px 10px 10px;
}
.nav > .nav-btn {
	display: none;
}
.nav > .nav-links {
	display: inline;
	float: right;
	font-size: 18px;
    position: absolute;
    right: 100px;
}
.nav > .nav-links > ul{
	list-style: none;
	display: flex;
	align-items: center;
	margin: 0;
	column-gap: 30px;
}
.nav > .nav-links > ul > li > a {
	display: inline-block;
	padding: 13px 10px 13px 10px;
	text-decoration: none;
	color: #fff;
	text-decoration: none;
	font-size: 14px;
	display: flex;
	align-items: center;
}
li.sub-nav a span {
    margin-left: 6px;
}
.nav > .nav-links > ul > li a span i {
    transform: rotate(0deg);
    transition: 0.3s ease;
}
header.active .nav > .nav-links > ul > li > a{
	color: #333;
}
ul.submenu > li > a {
	color: #333;
	text-decoration: none;
	width: 100%;
	display: block;
	padding: 12px;
	font-size: 14px;
}
.nav > #nav-check {
	display: none;
}
li.sub-nav{
	position: relative;
}
li.sub-nav ul {
	display: none;
	position: absolute;
	background-color: #fff;
	color: #333;
	min-width: 200px;
	height: 0;
	overflow-y: hidden;
	transition: 0.3s ease;
	border-radius: 10px
}
li.sub-nav input[type="checkbox"] {
	display: none;
}
a.header-contact-btn {
    background-color: #ffffff;
    color: #06548d !important;
    padding: 10px 15px;
    border: 0;
    border-radius: 4px;
    font-size: 14px;
    font-family: 'Epilogue', sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    cursor: pointer;
}
a.header-contact-btn:hover{
	color: rgba(255,255,255,0.7) !important;
}
a.header-contact-btn:hover svg{
	fill: rgba(255,255,255,1) !important;
}
a.header-contact-btn svg {
    fill: #03558e;
}*/

/* Navigation Styles */
.nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
nav {
  position: relative;
}
header.dark nav {
  background-color: rgba(255, 255, 255, 0.5);
}
header.light nav {
  background-color: rgba(0, 0, 0, 0.5);
}

header.active ul.main-nav > li > a {
  color: #333;
}

ul.main-nav {
  list-style-type: none;
  padding: 0px;
  font-size: 0px;
  max-width: 1000px;
  margin: 0 auto;
}

ul.main-nav > li {
  display: inline-block;
  padding: 0;
}

ul.main-nav > li > a {
  display: block;
  padding: 20px 30px;
  position: relative;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  box-sizing: border-box;
  text-decoration: none;
  line-height: 1.43;
}

ul.main-nav > li:hover {
  background-color: #f9f9f9;
}
ul.main-nav > li:hover > a {
  color: #333;
  font-weight: 400;
}

ul.main-nav > li ul.sub-menu-lists {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: block;
}

ul.main-nav > li ul.sub-menu-lists > li {
  padding: 2px 0;
}

ul.main-nav > li ul.sub-menu-lists > li > a {
  font-size: 14px;
  width: 100%;
  padding: 12px 15px;
  text-decoration: none;
  /*    background-color: #f8f8f8;*/
  border-radius: 6px;
  display: flex;
  align-items: center;
  column-gap: 20px;
}
ul.main-nav > li ul.sub-menu-lists > li:hover {
  background-color: #f8f8f8;
  border-radius: 10px;
}

.ic {
  position: fixed;
  cursor: pointer;
  display: inline-block;
  right: 25px;
  width: 32px;
  height: 24px;
  text-align: center;
  top: 0px;
  outline: none;
}

.ic.close {
  opacity: 0;
  font-size: 0px;
  font-weight: 300;
  color: #333;
  top: 8px;
  height: 40px;
  display: block;
  outline: none;
}

/* Menu Icons for Devices*/
.ic.menu {
  top: 17px;
  z-index: 20;
}

.ic.menu .line {
  height: 4px;
  width: 100%;
  display: block;
  margin-bottom: 6px;
}
.ic.menu .line-last-child {
  margin-bottom: 0px;
}

.sub-menu-head {
  margin: 10px 0;
}
.banners-area {
  margin-top: 20px;
  padding-top: 15px;
}

.banners-area img {
  width: 150px;
}
.banner-cta {
  background-color: #fff;
  color: #054c8e;
  padding: 15px 25px;
  border-radius: 50px;
}
.banner-cta:hover {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}
@media only screen and (max-width: 768px) {
  .sub-menu-head {
    color: orange;
  }
  .ic.menu {
    display: block;
  }
  header.dark .ic.menu .line {
    background-color: #fff;
  }
  header.light .ic.menu .line {
    background-color: #000;
  }
  .ic.menu .line {
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    background-color: #333;
  }

  .main-nav.active ~ .line {
    background-color: #fff !important;
  }

  .ic.menu.whiteline .line:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #fff !important;
    position: relative;
    top: 10px;
  }

  .ic.menu.whiteline .line:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: #fff !important;
    position: relative;
    top: -10px;
  }

  .ic.menu.whiteline .line:nth-child(2) {
    transform: translateY(15px);
    background-color: #fff !important;
    opacity: 0;
  }

  .ic.menu.active {
    outline: none;
  }
  .ic.menu.active ~ .ic.close {
    opacity: 1;
    z-index: 21;
    outline: none;
  }
  .ic.menu:hover,
  .ic.menu.active {
    opacity: 1;
  }
  nav {
    background-color: transparent;
  }

  /* Main Menu for Handheld Devices  */
  ul.main-nav {
    z-index: 2;
    padding: 50px 0;
    position: fixed;
    right: 0px;
    top: 0px;
    width: 0px;
    background-color: rgba(0, 0, 0, 1);
    height: 100%;
    overflow: auto;
    /*CSS animation applied : Slide from Right*/
    -webkit-transition-property: background, width;
    -moz-transition-property: background, width;
    -o-transition-property: background, width;
    transition-property: background, width;
    -webkit-transition-duration: 0.6s;
    -moz-transition-duration: 0.6s;
    -o-transition-duration: 0.6s;
    transition-duration: 0.6s;
  }

  .main-nav.active {
    width: 100%;
    max-width: 400px;
    background-color: rgba(0, 0, 0, 1);
  }

  ul.main-nav > * {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    opacity: 0;
  }
  .main-nav.active > * {
    opacity: 1;
  }

  ul.main-nav > li > a:after {
    display: none;
  }
  ul.main-nav > li:first-child {
    border-radius: 0px;
  }
  ul.main-nav > li {
    display: block;
    border-bottom: 1px solid #444;
  }

  ul.main-nav > li > a {
    font-weight: 600;
  }

  ul.main-nav > li ul.sub-menu-lists > li a {
    color: #eee;
    font-size: 14px;
  }
  .sub-menu-head {
    font-size: 16px;
  }
  ul.main-nav > li:hover {
    background-color: transparent;
  }
  ul.main-nav > li:hover > a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
  }
  .ic.menu.active ~ ul.main-nav > li > div.sub-menu-block {
    border-left: 0px solid #ccc;
    border-right: 0px solid #ccc;
    border-bottom: 0px solid #ccc;
    position: relative;
    visibility: visible;
    opacity: 1;
  }

  .sub-menu-block {
    display: none;
  }

  .banners-area {
    padding-bottom: 0px;
  }
  .banners-area div {
    margin-bottom: 15px;
  }
  .banners-area {
    border-top: 1px solid #444;
  }
}
.flex-row {
  display: flex;
  align-items: flex-start;
}
.menu-col {
  width: 100%;
}
.nav-icons img {
  width: 30px;
  height: 30px;
  display: flex;
}
.navlist-title {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 5px;
  font-weight: 600;
}
@media only screen and (min-width: 769px) {
  .ic.menu {
    display: none;
  }
  /* Main Menu for Desktop Devices  */
  ul.main-nav {
    display: block;
    position: relative;
  }
  .sub-menu-block {
    padding: 15px;
  }

  /* Sub Menu */
  ul.main-nav > li > div.sub-menu-block {
    visibility: hidden;
    background-color: #f9f9f9;
    position: absolute;
    margin-top: 0px;
    width: 100%;
    color: #333;
    left: 0;
    box-sizing: border-box;
    z-index: 3;
    font-size: 16px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    opacity: 0;

    /*CSS animation applied for sub menu : Slide from Top */
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    -webkit-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    transform: rotateX(90deg);
    -webkit-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center;
  }

  ul.main-nav > li:hover > div.sub-menu-block {
    background-color: #fff;
    border-radius: 10px;
    visibility: visible;
    opacity: 1;
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }

  ul.main-nav > li > div.sub-menu-block > * {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    opacity: 0;
  }

  ul.main-nav > li:hover > div.sub-menu-block > * {
    opacity: 1;
  }

  .sub-menu-head {
    font-size: 20px;
  }

  /* List Separator: Outer Border */
  header.dark ul.main-nav > li > a {
    border-right: 1px solid #bbb;
  }
  header.light ul.main-nav > li > a {
    border-right: 1px solid #666;
  }

  /* List Separator: Inner Border */
  ul.main-nav > li > a:after {
    content: '';
    width: 1px;
    height: 62px;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 2;
  }
  header.dark ul.main-nav > li > a:after {
    background-color: #777;
  }
  header.light ul.main-nav > li > a:after {
    background-color: #999;
  }

  /* Drop Down/Up Arrow for Mega Menu */
  ul.main-nav > li > a.mega-menu > span {
    display: block;
    vertical-align: middle;
  }
  ul.main-nav > li > a.mega-menu > span:after {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
    content: '';
    background-color: transparent;
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
  }
  .active ul.main-nav > li > a.mega-menu > span:after {
    border-top: 5px solid #333;
  }
  ul.main-nav > li:hover > a.mega-menu span:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 0px solid transparent;
    border-bottom: 5px solid #666;
  }
  .banners-area {
    border-top: 1px solid #ccc;
  }
}
/*navigation*/
.banner {
  top: 0px;
  min-height: 100vh;
  position: relative;
  background: rgb(5 84 141);
  //   background-image: linear-gradient(
  //     90deg,
  //     rgba(7, 169, 122, 1) 0%,
  //     #05548d 56%
  //   );
  //   background-image: linear-gradient(
  //     90deg,
  //     rgba(255, 255, 255, 0.9) 0%,
  //     rgba(7, 169, 122, 1) 48%,
  //     rgba(5, 84, 141, 1) 100%
  //   );
  overflow: hidden;
  //   background-image: url("./hero-2.jpg");
  background-size: cover;
  z-index: 0;
}
.banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;
  width: 85%;
  gap: 30px;
  justify-content: space-between;
}
.banner-text {
  width: 50%;
  text-align: left;
}
.banner-hero {
  position: relative;
  top: 40px;
}
.banner-hero img {
  max-width: 76%;
  position: relative;
  animation: animate 2s alternate infinite;
}
.banner-content h1 {
  font-size: 44px;
  font-weight: bold;
  margin-bottom: 30px;
}
.banner-content p {
  font-size: 18px;
  line-height: 32px;
  font-weight: 300;
}
header {
  position: fixed;
  width: 100%;
  z-index: 1;
  background-color: transparent;
}
.logo img {
  max-width: 230px;
  display: flex;
}
@keyframes animate {
  from {
    bottom: 0;
  }
  to {
    bottom: 20px;
  }
}
/*.banner-hero::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: -1;
    background-image: url(./images/hero-map.png);
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.4;
}*/

/*-------------------------- Contact Us ---------------------*/
.contact.banner {
  min-height: 60vh;
}
.contact-bg {
  background-color: aliceblue;
}
.contact-form {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 100px;
}
.contact-hero-img {
  max-width: 550px;
  width: 100%;
}
.contact-form form {
  max-width: 40%;
  width: 100%;
}
.contact-form .input-element {
  margin-bottom: 16px;
  /*    max-width: 400px;*/
}
.contact-form .input-element input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #c8c8c8;
}
.contact-form .input-element textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #c8c8c8;
}
.input-element .mandatory-sign {
  color: red;
  margin-left: 2px;
}

.contact-form .error-message {
  color: red;
  margin-top: 5px;
  font-size: 14px;
}
.contact-form .error-message-required {
  color: red;
  margin-top: -10px;
  margin-bottom: 7px;
  font-size: 10px;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.contact-form .input-element label {
  font-weight: 500;
}
.contact-img img {
  max-width: 100px;
  width: 100%;
}
.contact-info-block {
  display: flex;
  align-items: center;
  column-gap: 30px;
}
.contact-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contact-map {
  margin-top: 70px;
}
button.contact-submit-btn {
  background-color: #05548d;
  border: navajowhite;
  padding: 10px;
  width: 100%;
  border-radius: 50px;
  color: #fff;
}

/*-------------------------- Privacy Policy ---------------------*/
section.privacy-sec h2 {
  color: #05548d;
  font-size: 24px;
  margin: 35px 0 20px;
  width: 100%;
  background-color: #ededed;
  padding: 15px 15px;
  /* color: #4c4c4c;*/
}
section.privacy-sec h1 {
  color: #05548d;
}
section.privacy-sec h3 {
  font-size: 18px;
}
section.privacy-sec ul li {
  margin-bottom: 5px;
}

/* Navigation Styles */
.nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav {
  position: relative;
}
header.dark nav {
  background-color: rgba(255, 255, 255, 0.5);
}
header.light nav {
  background-color: rgba(0, 0, 0, 0.5);
}

header.active ul.main-nav > li > a {
  color: #333;
}

ul.main-nav {
  list-style-type: none;
  padding: 0px;
  font-size: 0px;
  max-width: 1000px;
  margin: 0 auto;
}

ul.main-nav > li {
  display: inline-block;
  padding: 0;
}

ul.main-nav > li > a {
  display: block;
  padding: 20px 30px;
  position: relative;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  box-sizing: border-box;
  text-decoration: none;
  line-height: 1.43;
}

ul.main-nav > li:hover {
  background-color: #f9f9f9;
}
ul.main-nav > li:hover > a {
  color: #333;
  font-weight: 400;
}

ul.main-nav > li ul.sub-menu-lists {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: block;
}

ul.main-nav > li ul.sub-menu-lists > li {
  padding: 2px 0;
}

ul.main-nav > li ul.sub-menu-lists > li > a {
  font-size: 14px;
  width: 100%;
  padding: 12px 15px;
  text-decoration: none;
  /*    background-color: #f8f8f8;*/
  border-radius: 6px;
  display: flex;
  align-items: center;
  column-gap: 20px;
}
ul.main-nav > li ul.sub-menu-lists > li:hover {
  background-color: #f8f8f8;
  border-radius: 10px;
}

.ic {
  position: fixed;
  cursor: pointer;
  display: inline-block;
  right: 25px;
  width: 32px;
  height: 24px;
  text-align: center;
  top: 0px;
  outline: none;
}

.ic.close {
  opacity: 0;
  font-size: 0px;
  font-weight: 300;
  color: #333;
  top: 8px;
  height: 40px;
  display: block;
  outline: none;
}

/* Menu Icons for Devices*/
.ic.menu {
  top: 17px;
  z-index: 20;
}

.ic.menu .line {
  height: 4px;
  width: 100%;
  display: block;
  margin-bottom: 6px;
}
.ic.menu .line-last-child {
  margin-bottom: 0px;
}

.sub-menu-head {
  margin: 10px 0;
}
.banners-area {
  margin-top: 20px;
  padding-top: 15px;
}

.banners-area img {
  width: 150px;
}
.banner-cta {
  background-color: #fff;
  color: #054c8e;
  padding: 15px 25px;
  border-radius: 50px;
}
.banner-cta:hover {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}
@media only screen and (max-width: 768px) {
  .sub-menu-head {
    color: orange;
  }
  .ic.menu {
    display: block;
  }
  header.dark .ic.menu .line {
    background-color: #fff;
  }
  header.light .ic.menu .line {
    background-color: #000;
  }
  .ic.menu .line {
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    background-color: #333;
  }

  .main-nav.active ~ .line {
    background-color: #fff !important;
  }

  .ic.menu.whiteline .line:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #fff !important;
    position: relative;
    top: 10px;
  }

  .ic.menu.whiteline .line:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: #fff !important;
    position: relative;
    top: -10px;
  }

  .ic.menu.whiteline .line:nth-child(2) {
    transform: translateY(15px);
    background-color: #fff !important;
    opacity: 0;
  }

  .ic.menu.active {
    outline: none;
  }
  .ic.menu.active ~ .ic.close {
    opacity: 1;
    z-index: 21;
    outline: none;
  }
  .ic.menu:hover,
  .ic.menu.active {
    opacity: 1;
  }
  nav {
    background-color: transparent;
  }

  /* Main Menu for Handheld Devices  */
  ul.main-nav {
    z-index: 2;
    padding: 50px 0;
    position: fixed;
    right: 0px;
    top: 0px;
    width: 0px;
    background-color: rgba(0, 0, 0, 1);
    height: 100%;
    overflow: auto;
    /*CSS animation applied : Slide from Right*/
    -webkit-transition-property: background, width;
    -moz-transition-property: background, width;
    -o-transition-property: background, width;
    transition-property: background, width;
    -webkit-transition-duration: 0.6s;
    -moz-transition-duration: 0.6s;
    -o-transition-duration: 0.6s;
    transition-duration: 0.6s;
  }

  .main-nav.active {
    width: 100%;
    max-width: 400px;
    background-color: rgba(0, 0, 0, 1);
  }

  ul.main-nav > * {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    opacity: 0;
  }
  .main-nav.active > * {
    opacity: 1;
  }

  ul.main-nav > li > a:after {
    display: none;
  }
  ul.main-nav > li:first-child {
    border-radius: 0px;
  }
  ul.main-nav > li {
    display: block;
    border-bottom: 1px solid #444;
  }

  ul.main-nav > li > a {
    font-weight: 600;
  }

  ul.main-nav > li ul.sub-menu-lists > li a {
    color: #eee;
    font-size: 14px;
  }
  .sub-menu-head {
    font-size: 16px;
  }
  ul.main-nav > li:hover {
    background-color: transparent;
  }
  ul.main-nav > li:hover > a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
  }
  .ic.menu.active ~ ul.main-nav > li > div.sub-menu-block {
    border-left: 0px solid #ccc;
    border-right: 0px solid #ccc;
    border-bottom: 0px solid #ccc;
    position: relative;
    visibility: visible;
    opacity: 1;
  }

  .sub-menu-block {
    display: none;
  }

  .banners-area {
    padding-bottom: 0px;
  }
  .banners-area div {
    margin-bottom: 15px;
  }
  .banners-area {
    border-top: 1px solid #444;
  }
}
.flex-row {
  display: flex;
  align-items: flex-start;
}
.menu-col {
  width: 100%;
}
.nav-icons img {
  width: 30px;
  height: 30px;
  display: flex;
}
.navlist-title {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 5px;
  font-weight: 600;
}
@media only screen and (min-width: 769px) {
  .ic.menu {
    display: none;
  }
  /* Main Menu for Desktop Devices  */
  ul.main-nav {
    display: block;
    position: relative;
  }
  .sub-menu-block {
    padding: 15px;
  }

  /* Sub Menu */
  ul.main-nav > li > div.sub-menu-block {
    visibility: hidden;
    background-color: #f9f9f9;
    position: absolute;
    margin-top: 0px;
    width: 100%;
    color: #333;
    left: 0;
    box-sizing: border-box;
    z-index: 3;
    font-size: 16px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    opacity: 0;

    /*CSS animation applied for sub menu : Slide from Top */
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    -webkit-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    transform: rotateX(90deg);
    -webkit-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center;
  }

  ul.main-nav > li:hover > div.sub-menu-block {
    background-color: #fff;
    border-radius: 10px;
    visibility: visible;
    opacity: 1;
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }

  ul.main-nav > li > div.sub-menu-block > * {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    opacity: 0;
  }

  ul.main-nav > li:hover > div.sub-menu-block > * {
    opacity: 1;
  }

  .sub-menu-head {
    font-size: 20px;
  }

  /* List Separator: Outer Border */
  header.dark ul.main-nav > li > a {
    border-right: 1px solid #bbb;
  }
  header.light ul.main-nav > li > a {
    border-right: 1px solid #666;
  }

  /* List Separator: Inner Border */
  ul.main-nav > li > a:after {
    content: '';
    width: 1px;
    height: 62px;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 2;
  }
  header.dark ul.main-nav > li > a:after {
    background-color: #777;
  }
  header.light ul.main-nav > li > a:after {
    background-color: #999;
  }

  /* Drop Down/Up Arrow for Mega Menu */
  ul.main-nav > li > a.mega-menu > span {
    display: block;
    vertical-align: middle;
  }
  ul.main-nav > li > a.mega-menu > span:after {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
    content: '';
    background-color: transparent;
    display: inline-block;
    margin-left: 10px;
    vertical-align: middle;
  }
  .active ul.main-nav > li > a.mega-menu > span:after {
    border-top: 5px solid #333;
  }
  ul.main-nav > li:hover > a.mega-menu span:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 0px solid transparent;
    border-bottom: 5px solid #666;
  }
  .banners-area {
    border-top: 1px solid #ccc;
  }
}

.banner .first-section .contact.banner {
  min-height: 60vh;
}
.banner .first-section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 85%;
}

section.banner h2 {
  font-size: 40px;
  color: #fff;
  font-family: 'Bebas Neue', sans-serif;
}
.h1-title-bluebg {
  font-size: 44px;
  color: #fff;
  font-weight: 600;
}
.banner .first-section p {
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
}
footer {
  background-color: #02254b;
  color: #fff;
  padding: 50px 0;
}
.footer-logo img {
  max-width: 230px;
}
.footer-column ul {
  padding-left: 0;
}
.footer-intro-column p {
  color: rgba(255, 255, 255, 0.7);
}
.footer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  flex-wrap: wrap;
}
.footer-wrapper .footer-column .footer-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 25px;
  margin-top: 0;
  position: relative;
}
.footer-column ul li {
  list-style: none;
  font-weight: 300;
  margin-bottom: 8px;
}
.footer-column ul li a {
  color: #c1c1c1;
  text-decoration: none;
  display: inline-block;
}
.footer-intro-column .footer-logo {
  margin-bottom: 20px;
}
.footer-intro-column {
  max-width: 330px;
  width: 100%;
  position: relative;
  /*    z-index: 2;*/
}
.footer-social-links {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}
.footer-column .footer-links li a {
  position: relative;
  display: inline-block;
}
.footer-column .footer-links li a:hover {
  color: #fff;
}
/*.footer-column .footer-links li a::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0px;
      height: 1px;
      width: 0px;
      background-color: #c1c1c1;
      transition: 0.3s ease;
  }*/
/*.footer-column .footer-links li a:hover::before {
       content: '';
      position: absolute;
      left: 0;
      bottom: 0px;
      height: 1px;
      width: 100%;
      background-color: aliceblue;
      transition: 0.3s ease;
  }*/
.footer-column .footer-social-links li svg {
  fill: #c1c1c1;
  width: 30px;
  display: flex;
}
.footer-column ul li a:hover svg {
  fill: #fff;
}
h2.footer-title::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 50px;
  height: 2px;
  background-color: #6c63ff;
}
.footer-contact-option {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 10px;
}
.footer-contact-option svg {
  fill: rgba(255, 255, 255, 0.7);
}
.footer-contact-option a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}
.copyright {
  background-color: #001124;
  color: #ededed;
  font-size: 12px;
  /* text-align: center; */
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*.footer-intro-column::before {
      content: '';
      background-image: url(./images/hero-map.png);
      position: absolute;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: -20px 0px;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0.5;
  }*/
/*-------------------------- Get in touch ---------------------*/
section.git {
  position: relative;
}
.get-in-touch {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #f0f8ff;
  z-index: 3;
  bottom: -696px;
  transition: 0.6s ease;
}
.get-in-touch.git-open {
  bottom: 0;
  position: absolute;
  overflow-y: scroll;
  height: 100vh;
  z-index: 5;
  transition: 0.6s ease;
}
.get-in-touch.git-open .git-header {
  position: relative !important;
  transition: 0.6s ease;
}
body:has(.get-in-touch.git-open) {
  overflow: hidden;
}

.git-header {
  background-color: #07a97a;
  height: 50px;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  transition: 0.6s ease;
}
/* .get-in-touch.git-open .git-header {
      position: relative;
      transition: 0.3s ease;
  } */
.git-header .git-btn {
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  position: relative;
  z-index: 0;
}
.git-btn:before {
  content: '';
  background-color: #07a97a;
  position: absolute;
  width: 50px;
  height: 50px;
  top: -39px;
  transform: rotate(45deg);
  z-index: -1;
}
.git-btn:after {
  content: '';
  // background-image: url("./pointer.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: -36px;
  z-index: -1;
  width: 24px;
  height: 24px;
  left: 15px;
}
.get-in-touch.git-open .git-btn:before {
  top: 9px;
}
.get-in-touch.git-open .git-btn:after {
  top: 35px;
  transform: rotate(180deg);
}
.git-main .contact-form {
  justify-content: space-between;
}
.git-main .contact-form form {
  max-width: 50%;
  position: relative;
  /*     display: flex; */
  flex-wrap: wrap;
}
.git-main .contact-form form .input-element {
  /*max-width: 390px;
    width: 100%;
    display: flow;*/
  margin-right: 50px;
}
.git-main .contact-form form div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.git-main .contact-form form .git-form-cta button {
  max-width: 190px;
  padding: 10px 30px;
}
.git-form-cta {
  margin-top: 15px;
}
.git-concent {
  font-size: 12px;
  margin-top: 40px;
  width: 65%;
}
.git-image {
  width: 50%;
  text-align: right;
}
.git-image img {
  max-width: 500px;
  width: 100%;
}
#thanksMsg {
  position: relative;
  font-size: 30px;
  font-weight: 700;
  /* display: flex; */
  align-items: center;
  gap: 20px;
}
#thanksMsg label {
  top: 10px;
  position: relative;
}
.visibility-hidden {
  visibility: hidden;
}
.visibility-visible {
  visibility: visible;
}
.display-none {
  display: none;
}
.display-block {
  display: block;
}
.display-flex {
  display: flex;
}
/* #thanksMsg{
      display: none;
  } */
@media (max-width: 768px) {
  .container {
    padding: 0 20px;
  }
  .logo .white-logo {
    display: none;
  }
  .logo .default-logo {
    display: block;
  }
  /*Navigation*/
  header {
    background-color: #fff;
    padding: 10px 0;
    z-index: 4;
  }
  ul.main-nav > li ul.sub-menu-lists > li:hover {
    background-color: #f8f8f8;
    border-radius: 10px;
  }
  ul.main-nav > li ul.sub-menu-lists > li:hover a {
    color: #333;
  }
  ul.main-nav > li ul.sub-menu-lists {
    margin-bottom: 10px;
  }
  ul.main-nav > li ul.sub-menu-lists > li {
    padding: 2px 0;
    margin: 0px 15px;
  }
  .mega-menu::before {
    content: '';
    position: absolute;
    right: 30px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    width: 8px;
    height: 8px;
    z-index: 1;
    transform: rotate(45deg);
    transition: 0.3s ease;
  }
  a.mega-menu.open::before {
    transform: rotate(225deg);
    transition: 0.3s ease;
  }
  .ic.menu.whiteline::before {
    content: '';
    position: absolute;
    background-color: #000;
    height: 35px;
    width: 35px;
    left: -1px;
    top: -4px;
  }
  .banner-content {
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
    gap: 0px;
  }
  .banner-text {
    width: 100%;
    text-align: left;
  }
  .banner-content h1 {
    font-size: 28px;
  }
  .banner-content p {
    font-size: 16px;
    line-height: 24px;
  }
  .banner-hero img {
    max-width: 320px;
  }
  /*.banner-hero img {
        display: none;
    }*/
}
