@import '../../styles/ConstVariable';

.DatePicker-container {
  margin-bottom: 20px;
  input[type='date'] {
    background: #ffffff;
    border: 1px solid $inputBorderColor;
    border-radius: 8px;
    height: 40px;
    color: #666666;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    padding-left: 10px;
    padding-right: 10px;
  }
  label {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #222222;
    display: block;
    margin-bottom: 5px;
  }
}
