.loop-start-tag {
    padding-left: 25px;
    position: relative;
  }
  
  .loop-start-tag::before {
    content: '';
    background-image: url('../../../assets/custom-tag.svg');
    width: 19px;
    height: 13px;
    left: 0;
    top: 3px;
    visibility: visible;
    position: absolute;
  }