.resize-container {
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 150px;
  // max-width: 300px;
  display: flex;
  position: sticky;
  top: 0;
  min-width: 300px;

  // border-right: #e9e9e9 1px solid;
  // flex-direction: row;
  .sidebar-resizer {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 6px;
    justify-self: flex-end;
    cursor: col-resize;
    resize: horizontal;
  }

  .element-container {
    flex: 1;

    .heading {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
    }

    .element-items {
      min-height: 345px;
      background: #ffffff;
      border: 1px solid #00580a;
      border-radius: 8px;
      margin-top: 5px;
      display: flex;
      flex-direction: column;
      align-items: start;
      row-gap: 5px;
      padding: 12px;

      .category-section {
        //   padding-left: 5px;
        display: block;
        width: 100%;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 10px;
        //   margin-top: 5px;
        border: 1px solid green;
        border-radius: 8px;

        .elements-list {
          padding: 0 7px 0;
          max-height: 340px;
          overflow-y: scroll;
        }

        .elements-list::-webkit-scrollbar {
          width: 4px;
        }

        .elements-list::-webkit-scrollbar-track {
          box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
        }

        .elements-list::-webkit-scrollbar-thumb {
          background-color: rgb(139 139 139);
          outline: 1px solid slategrey;
          border-radius: 20px;
          height: 50px;
        }

        .active-font {
          color: #222222;
        }

        .normal-font {
          color: #666666;
        }

        .category-title {
          padding: 10px;
          display: flex;
          align-items: center;
          column-gap: 10px;
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          user-select: none;
        }

        .item {
          margin-bottom: 7px;
          border: 1px solid #cccccc;
          border-radius: 5px;
          background: #c5ebc9;
          height: 30px;
          display: flex;
          padding: 5px;
          align-items: center;
          cursor: pointer;

          p {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #00580a;
          }
        }
      }
    }
  }
}

.elements-section .resize-container {
  min-width: 300px !important;
}

.properties-wrapper .element-items .category-section .category-title {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 10px 10px;
  font-weight: bold;
  background-color: #f3f3f3;
  user-select: none;
}

.properties-wrapper .element-items .category-section .category-title.active-font {
  margin-bottom: 10px;
}

.properties-wrapper .element-items .category-section {
  border: 1px solid #dadada;
}

.properties-wrapper .element-items .category-section .elements-list {
  padding: 0 10px;
}

.properties-wrapper .cm-form-elements select {
  border-radius: 0;
}