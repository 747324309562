.preview-button {
    margin-bottom: 15px;
    // .ButtonContainer {
      button {
        background: transparent;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        // border-radius: 12px;
        width: fit-content;
        padding: 10px;
        height: fit-content;
        color: #00580a;
        cursor: pointer;
        border: 1px solid;
        width: 160px;
      }
    // }
  }

  *:focus{
    outline: none;
  }
  *[contenteditable="true"]{
    border: 2px solid transparent;
    position: relative;
  }
  *[contenteditable="true"]:focus{
    border: 2px solid #0075ff;
  }
  .cm-component-action-box {
      display: block;
      position: absolute;
        top: -30px;
    right: 0;
  }
  *[contenteditable="true"]:focus .cm-component-action-box {
      display: block;
  }
  .cm-component-action {
      display: flex;
      align-items: center;
/*	    gap: 10px;*/
      background-color: #4c4c4c;
      max-width: max-content;
      color: #fff;
      border-radius: 5px;
  }
  .cm-component-action .cm-actions {
      display: flex;
      align-items: center;
      gap: 2px;
      font-size: 11px;
      cursor: pointer;
      padding: 5px;
      border-right: 1px solid #909090;
      font-weight: normal;
      user-select: none;
  }
  .cm-component-action .cm-actions svg {
      width: 11px;
      height: 11px;
  }
  .cm-component-action .cm-actions:last-child{
    border-right: 0px;
  }
