@import '../../styles/ConstVariable';

* {
  box-sizing: border-box;
  outline: none;
}
.forgot-password-page {
  padding: 0;
  margin: 0;
  background-color: #023050;
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  height: calc(100vh - 70px);
  overflow: hidden;
}
.forgot-container {
  max-width: 500px;
  width: 100%;
  background-color: #fff;
  height: auto;
  padding: 50px 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: relative;

  input{
    border-color: #333;
    border-radius: 0;
  }
}
.sign-logo {
  display: flex;
  justify-content: center;
}
.sign-logo img {
  max-width: 220px;
}
.forgot-container .recover-password-text {
  font-size: 32px;
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
}
.login-button {
  width: 100%;
  display: block;
  background-color: #07548b;
  padding: 10px 20px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-top: 30px;
  text-decoration: none;
}
.login-button:hover {
  background-color: #053b61;
  color: #fff;
  text-decoration: none;
}
.LogInButton2 {
  text-decoration: underline;
}

.login-link {
  font-size: 12px;
  text-align: center;
  margin: 20px 0 0;
}

/*Forgot Password*/
.input-forgotpassword-form .input-element {
  margin-bottom: 16px;
}
.input-forgotpassword-form .input-element input {
  width: 100%;
  padding: 10px;
  border: 1px solid #92b8b8;
  color: #000000;
}

/*Media Query mobile*/
@media (max-width: 600px) {
  .forgot-container {
    padding: 50px 50px;
  }
}
