@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;700&family=Poppins:wght@100;200;400;500;600;700&display=swap');

.signup-page {
  padding: 0;
  margin: 0;
  background-color: #023050;
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  height: calc(100vh);
  overflow: hidden;
  position: relative;
}

.signup-container {
  max-width: 500px;
  width: 100%;
  background-color: #fff;
  height: auto;
  padding: 50px 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sign-logo {
  display: flex;
  justify-content: center;
}

.sign-logo img {
  max-width: 220px;
}

.Sign-up-text {
  font-size: 32px;
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
}

.login-option-btn {
  border: 1px solid #d0dbdb;
  padding: 10px 15px;
  display: inline-block;
  width: 100%;
  text-decoration: none;
  color: #767676;
}

.login-option-btn .button-text {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.login-divider-wrapper {
  position: relative;
  color: #dfeaea;
}

.login-divider {
  display: block;
  margin: 30px 0;
  background-color: #ccc;
  height: 3px;
}

.or {
  position: absolute;
  top: -9px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 0px 12px;
  color: #bdcccc;
  font-weight: 700;
}

.input-signup-form {
  display: none;
}

.input-element {
  margin-bottom: 16px;
}

.input-element input {
  width: 100%;
  padding: 10px;
  border: 1px solid #92b8b8;
  color: #000000;
}

.remember-check {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.remember-check input[type='checkbox'] {
  margin: 0;
}

.remember-check label {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  color: #757575;
  user-select: none;
}

.signup-button,
.signup-w-e-button {
  width: 100%;
  display: block;
  background-color: #07548b;
  padding: 10px 20px;
  text-align: center;
  color: #fff !important;
  font-size: 16px;
  font-weight: 400;
  margin-top: 30px;
  text-decoration: none;
}

.signup-button:hover,
.signup-w-e-button:hover {
  background-color: #053b61;
  color: #fff !important;
  text-decoration: none;
}

.login-link {
  font-size: 12px;
  text-align: center;
  margin: 20px 0 0;
}

.log-in {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .signup-container {
    padding: 50px 50px;
  }
}
