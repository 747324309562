.social-component-container {
  max-width: 500px;
  height: 500px;
  border: 1px solid #348ddb;
}
.social-template-content {
  margin: 25px;
  max-height: -webkit-fill-available;
  height: 100%;
  position: relative;
  border: 15px solid #348ddb;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.social-template-content * {
  border: 2px solid transparent;
  text-align: center;
}
.social-template-content > *:hover,
.social-template-content > *:focus {
  border: 2px solid #8b3dff;
}
.social-template-content input {
  font-family: 'Montserrat', sans-serif;
  background-color: transparent;
  width: 100%;
  color: #1e3653;
}
.social-template-content input:focus,
.social-template-title textarea:focus {
  outline: none;
}
.social-template-title textarea {
  font-size: 3em;
  line-height: 1em;
  font-weight: bold;
  color: #348ddb;
  max-width: -webkit-fill-available;
  font-family: 'Montserrat', sans-serif;
  background-color: transparent;
  overflow: hidden;
  resize: none;
}
