@import '../../styles/ConstVariable';
.nextContainer {
  width: fit-content;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #4294ff;
    border: 1px solid #44b194;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    border-radius: 5px;
    width: 150px;
    height: 40px;
    color: #ffffff;
    cursor: pointer;
  }

  button:hover {
    background-color: orange;
    color: #fff;
    border: 1px solid #fff;
  }

  button span svg {
    margin-left: 6px;
    height: 9px;
  }
}
