@import '../../styles/ConstVariable';

.audienceSocialmedia-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: $pageFontFamily;
  padding-bottom: 70px;
  padding: 20px;
  // border: 1px solid #eeeeee;
  // border-radius: 32px;
  margin-top: 10px;
  margin-right: 30px;
  margin-left: 30px;
  width: 95%;
  margin-left: 30px;
  // background-color: $pageBackgroundColor;

  .grid-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    margin-top: 20px;

    .subHeading {
      font-size: 20px;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .subHeading-Text {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height */
      color: #222222;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .navigationButtons {
    margin-top: 70px;
    display: flex;
    column-gap: 50px;
    margin-bottom: 20px;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .button-separator {
    height: 10px;
    width: 100%;
  }
}
