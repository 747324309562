.cookie-box {
    display: block;
    background-color: #e6e6e6;
    width: 50%;
    height: auto;
    padding: 20px 30px;
    border-radius: 10px;
    border: 1px solid #000;
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 99999;
}
.cookie-box .cookie-flex .content {
  color: #333;
  margin-right: 30px;
}
.cookie-box .cookie-flex .content h2 {
  margin: 0;
  font-size: 16px;
}
.cookie-box .cookie-flex .content h2:nth-child(2) {
  margin-bottom: 20px;
  margin-top: 0px;
}
.cookie-box .cookie-flex .content p {
  font-size: 14px;
}
.cookie-flex {
  display: flex;
  align-items: center;
}
.cookie-box .cta-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.cookie-btn {
  background-color: #05548d; /*#d93954*/
  color: #fff;
  padding: 12px;
  display: inline-block;
  width: 200px;
  text-align: center;
  text-decoration: none;
/*  font-weight: bold;*/
  transition: 0.3s ease;
  font-size: 13px;
}
.cookie-btn:hover {
  background-color: #fff;
  color: #05548d;
  transition: 0.3s ease;
/*  transform: scale(1.08);*/
  text-decoration: none;
}
.cookie-btn:visited {
  text-decoration: none;
}
.cookie-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 10;
}
.cookie-side-overlay.open-side-cookie {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 10;
}
.side-cookie-panel {
  position: fixed;
  bottom: 0;
  background-color: #e6e6e6;
  max-width: 480px;
  right: -480px;
  top: 0;
  z-index: 11;
  transition: right 0.5s ease;
}
.cookie-logo {
  padding: 20px 25px;
  border-bottom: 1px solid #fff;
}
.cookie-side-panel-section {
  padding: 20px 25px;
  color: #333;
}
.cookie-logo img {
  max-width: 200px;
  display: flex;
}
.cookie-side-panel-section .cookie-p {
  font-size: 13px;
}
.cookie-side-panel-section .cookie-h2 {
  font-weight: 600;
  font-size: 20px;
  margin-top: 0;
}
.cookie-side-panel-section h4 {
  font-size: 15px;
}
.cookie-side-panel-section .href-tag {
  color: #fff;
}
.cookie-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cookie-type .switchSmall {
  display: inline-block;
  margin-bottom: 0;
}
.cookie-type .switchSmall input {
  display: none;
}
.cookie-type .switchSmall small {
  display: inline-block;
  width: 32px;
  height: 16px;
  background: #333333;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
  margin: 10px 0;
}
.cookie-type .switchSmall small:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  top: 3px;
  left: 3px;
  transition: 0.3s;
  box-shadow: -3px 0 3px rgba(0, 0, 0, 0.1);
}
.cookie-type .switchSmall input:checked ~ small {
  background: #828282;
  transition: 0.3s;
}
.cookie-type .switchSmall input:checked ~ small:before {
  transform: translate(16px, 0px);
  transition: 0.3s;
}
.cookie-side-panel-section-scroll {
  overflow-y: scroll;
  height: 70vh;
  border-bottom: 1px solid #fff;
}
.cookie-side-panel-section-scroll::-webkit-scrollbar {
  width: 10px;
}
.cookie-side-panel-section-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.cookie-side-panel-section-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.cookie-side-panel-section .cookie-btn {
  width: 100%;
  margin-bottom: 10px;
  transform: scale(1);
}
.hr-tag{
    border-color: #ffffff;
}
.href-tag{
    color: #333;
}


.side-cookie-panel.active {
  right: 0 !important;
  transition: 0.5s ease;
}