.social-cm-tab input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.row {
  display: flex;
}

.row .cm-col {
  flex: 1 1;
  width: 230px;
}

/*.row .col:last-child {
margin-left: 1em;
}*/

/* Accordion styles */
.social-cm-tabs {
  border-radius: 8px;
  /* overflow: hidden;
box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);*/
}

.social-cm-tab {
  width: 100%;
  color: white;
  overflow: hidden;
  margin-bottom: 0px;
  box-shadow: 0px 0px 5px 0px #d0d0d0;
  border-radius: 5px;
  position: relative;
}
.social-cm-tab .tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: #ffffff;
  cursor: pointer;
  color: #000;
  font-size: 15px;
  font-weight: normal;
  /* Icon */
}
.social-cm-tab .tab-label:hover {
  background: #ffffff;
}
.social-cm-tab input[type='checkbox']:not(:checked) ~ label::before {
  content: '';
  position: absolute;
  box-shadow: 0px 0px 5px 0px #ececec;
  width: 25px;
  height: 25px;
  right: 20px;
  top: 15px;
  border-radius: 50%;
  transition: ease-in 0.3s;
}
.social-cm-tab input[type='checkbox']:checked ~ label::before {
  content: '';
  position: absolute;
  background-color: blue !important;
  width: 25px;
  height: 25px;
  right: 20px;
  top: 12px;
  border-radius: 50%;
  transition: ease-in 0.3s;
}
.social-cm-tab input[type='checkbox']:not(:checked) ~ label::after {
  content: '';
  width: 6px;
  height: 6px;
  text-align: center;
  transition: all 0.35s;
  /* color: #000; */
  border-top: 2px solid #4a3aff;
  border-right: 2px solid #4a3aff;
  /* z-index: 1; */
  transform: rotate(45deg) translateY(-50%);
  position: relative;
  right: 15px;
  top: 9px;
}
.social-cm-tab input[type='checkbox']:checked ~ label::after {
  content: '';
  width: 6px;
  height: 6px;
  text-align: center;
  transition: all 0.35s;
  /* color: #000; */
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  /* z-index: 1; */
  transform: rotate(135deg) translateY(-50%);
  position: relative;
  right: 16px;
  top: 1px;
}

/*.tab-label::after {
content: "❯";
width: 1em;
height: 1em;
text-align: center;
transition: all 0.35s;
color: #000;
}*/

.tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all 0.35s;
}
.tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}
.tab-close:hover {
  background: #fff;
}

.social-cm-tab input:checked + .social-cm-tab .tab-label {
  background: #fff;
}
.social-cm-tab input:checked + .social-cm-tab .tab-label::after {
  transform: rotate(90deg);
}
.social-cm-tab input:checked ~ .tab-content {
  max-height: 100vh;
  padding: 1em;
  padding-top: 0;
}
.social-cm-tab:has(.social-cm-tab input:checked) {
  border: 2px solid #4a3aff;
}
.elements-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow-y: scroll;
  height: 65vh;
}
.social_elements {
  text-align: center;
  background-color: #eee;
  padding: 12px 15px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  cursor: pointer;
}
.social_elements img {
  width: 32px;
}
.social_elements .element-name {
  font-size: 12px;
  line-height: 14px;
}
.social_elements:hover {
  background-color: #e2e2e2;
}
@media screen and (max-width: 590px) {
  .social-cm-tab .tab-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: #ffffff;
    cursor: pointer;
    color: #000;
    font-size: 16px;
  }
}
