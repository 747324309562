@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;700&family=Poppins:wght@100;200;400;500;600;700&display=swap');
@import '../../styles/ConstVariable';

* {
  box-sizing: border-box;
  outline: none;
}

v1 {
  padding: 0;
  margin: 0;
  background-color: #023050;
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
}

.login-container {
  // max-width: 500px;
  // width: 100%;
  background-color: #fff;
  // height: auto;
  // padding: 50px 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  input {
    border-color: #333;
    border-radius: 0;
  }
}

.sign-logo {
  display: flex;
  justify-content: center;
}

.sign-logo img {
  max-width: 220px;
}

.login-container h1 {
  font-size: 24px;
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
}

.login-option-btn {
  border: 1px solid #d0dbdb;
  padding: 10px 15px;
  display: inline-block;
  width: 100%;
  text-decoration: none;
  color: #767676;
}

.login-option-btn .button-text {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.login-divider-wrapper {
  position: relative;
  color: #dfeaea;
}

.login-divider {
  display: block;
  margin: 30px 0;
  background-color: #ccc;
  height: 3px;
}

.or {
  position: absolute;
  top: -9px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 0px 12px;
  color: #bdcccc;
  font-weight: 700;
}

.input-login-form .input-element {
  margin-bottom: 16px;
}

.input-login-form .input-element input {
  width: 100%;
  padding: 10px;
  border: 1px solid #92b8b8;
  color: #000000;
}

.remember-check {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.remember-check input[type='checkbox'] {
  margin: 0;
}

.remember-check label {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  color: #757575;
  user-select: none;
}

.flex-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.forgot-password {
  font-size: 12px;
  display: inline;
  cursor: pointer;
}

.login-button {
  width: 100%;
  display: block;
  background-color: #07548b;
  padding: 10px 20px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-top: 30px;
  text-decoration: none;
  border: none;
}

.login-button:hover {
  background-color: #053b61;
  color: #fff;
  text-decoration: none;
}

.login-option-btn:hover {
  text-decoration: none;
}

.login-option-btn:hover span svg {
  transform: scale(1.1);
}

/*Sign Up*/
.signup-link {
  font-size: 12px;
  text-align: center;
  margin: 20px 0 0;
}

.input-signup-form {
  display: none;
}

.signup-container {
  max-width: 500px;
  width: 100%;
  background-color: #fff;
  height: auto;
  padding: 50px 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.signup-container .input-element {
  margin-bottom: 16px;
}

.signup-container .input-element input {
  width: 100%;
  padding: 10px;
  border: 1px solid #92b8b8;
  color: #000000;
}

.signup-container h1 {
  font-size: 32px;
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
}

.signup-w-e-button {
  width: 100%;
  display: block;
  background-color: #07548b;
  padding: 10px 20px;
  text-align: center;
  color: #fff !important;
  font-size: 16px;
  font-weight: 400;
  margin-top: 30px;
  text-decoration: none;
}

.signup-w-e-button:hover {
  background-color: #053b61;
  color: #fff !important;
  text-decoration: none;
}

.signup-button {
  width: 100%;
  display: block;
  background-color: #07548b;
  padding: 10px 20px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-top: 30px;
  text-decoration: none;
}

.signup-button:hover {
  background-color: #053b61;
  color: #fff;
  text-decoration: none;
}

.login-link {
  font-size: 12px;
  text-align: center;
  margin: 20px 0 0;
}

.input-forgotpassword-form .input-element {
  margin-bottom: 16px;
}

.input-forgotpassword-form .input-element input {
  width: 100%;
  padding: 10px;
  border: 1px solid #92b8b8;
  color: #000000;
}

@media (max-height: 600px) {
  .login-container {
    max-width: 90%;
    max-height: 80%;
    width: auto;
    height: auto;
    padding: 40px 60px;
  }
}

@media (max-height: 500px) {
  .login-container {
    max-width: 90%;
    width: auto;
    height: auto;
    padding: 30px 40px;
  }
}

@media (max-height: 400px) {
  .login-container {
    max-width: 90%;
    width: auto;
    height: auto;
    padding: 20px 20px;
  }
}

@media (max-width: 600px) {
  .login-container {
    max-width: 90%;
    width: auto;
    height: auto;
  }
}

.log-in-page {
  padding: 0;
  margin: 0;
  background-color: #023050;
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  height: calc(100vh);
  overflow: hidden;
  position: relative;
}
.login-container {
  max-width: 500px;
  width: 100%;
  background-color: #fff;
  height: auto;
  padding: 50px 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.sign-logo {
  display: flex;
  justify-content: center;
}
.sign-logo img {
  max-width: 220px;
}
.login-container .log-in-text {
  font-size: 32px;
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
}
.login-option-btn {
  border: 1px solid #d0dbdb;
  padding: 10px 15px;
  display: inline-block;
  width: 100%;
  text-decoration: none;
  color: #767676;
}
.login-option-btn .button-text {
  display: flex;
  align-items: center;

  &__content {
    background: #f9faf5;
    box-shadow: 0px 1px 4px 2px rgba(97, 97, 97, 0.15);
    border-radius: 24px;
    margin-left: auto;
    margin-right: 10%;
    width: 463px;
    height: 520px;
    margin-bottom: 20px;

    &__logo {
      img {
        // display: block;
        // margin-left: auto;
        // margin-right: auto;

        // width: 90%;
        // height: 80px;
        padding-top: 20px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 74%;
        height: 57px;
      }

      // svg {
      //   display: block;
      //   margin-left: auto;
      //   margin-right: auto;
      //   padding-top: 30px;
      //   margin-bottom: 30px;
      // }
    }

    &__title {
      display: block;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 32px;
      color: #222222;
      // text-align: center;
      // margin-top: 15px;
      margin-bottom: 45px;
      padding-left: 69px;
    }

    &__username {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
      width: 367px;
    }

    &__password {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
      width: 367px;
    }

    &__button {
      border: none;
      width: 367px;
      height: 43px;
      background: $loginButtonBackgroundColor;
      color: $loginButtonTextColor;
      border-radius: 12px;
      display: block;
      margin-left: auto;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      margin-right: auto;
      margin-bottom: 30px;
      cursor: pointer;
    }

    &__forgot-password {
      margin-bottom: 30px;
      text-align: center;

      text-decoration-line: underline;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      color: $linkColor;
    }

    &__signup {
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */

      color: #222222;

      a {
        text-decoration-line: underline;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: $linkColor;
      }
    }
  }
}
