@import '../../styles/ConstVariable';
.SystemTemplateList-container {
  border: 1px solid #eeeeee;
  border-radius: 32px;
  margin: 10px;
  width: 95%;
  height: fit-content;
  background-color: $pageBackgroundColor;
  padding: 20px;
  font-family: $pageFontFamily;
  margin-left: 30px;
  margin-right: 30px;
  .text-container {
    margin-left: 10px;
  }
  .flex-style {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    .flex-style {
      margin-left: auto;
      button {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
  .headings {
    display: grid;
    grid-template-columns: 0.6fr 1fr 0.6fr 0.6fr 0.6fr 0.6fr;
    column-gap: 30px;
    font-style: normal;
    padding: 7px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */
    color: #666666;
  }
  .lists {
    margin-top: 20px;
  }
}



