/*-------------------------- Footer ---------------------*/
footer {
    background-color: #02254b;
    color: #fff;
    padding: 50px 0;
}

.footer-logo img {
    max-width: 230px;
}

.footer-column ul {
    padding-left: 0;
}

.footer-intro-column p {
    color: rgba(255, 255, 255, 0.7);
}

.footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    flex-wrap: wrap;
}

.footer-wrapper .footer-column .footer-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 25px;
    margin-top: 0;
    position: relative;
}

.footer-column ul li {
    list-style: none;
    font-weight: 300;
    margin-bottom: 8px;
}

.footer-column ul li a {
    color: #c1c1c1;
    text-decoration: none;
    display: inline-block;
}

.footer-intro-column .footer-logo {
    margin-bottom: 20px;
}

.footer-intro-column {
    max-width: 330px;
    width: 100%;
    position: relative;
    /*    z-index: 2;*/
}

.footer-social-links {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
}

.footer-column .footer-links li a {
    position: relative;
    display: inline-block;
}

.footer-column .footer-links li a:hover {
    color: #fff;
}

/*.footer-column .footer-links li a::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0px;
    height: 1px;
    width: 0px;
    background-color: #c1c1c1;
    transition: 0.3s ease;
}*/
/*.footer-column .footer-links li a:hover::before {
	 content: '';
    position: absolute;
    left: 0;
    bottom: 0px;
    height: 1px;
    width: 100%;
    background-color: aliceblue;
    transition: 0.3s ease;
}*/
.footer-column .footer-social-links li svg {
    fill: #c1c1c1;
    width: 30px;
    display: flex;
}

.footer-column ul li a:hover svg {
    fill: #fff;
}

h2.footer-title::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 50px;
    height: 2px;
    background-color: #6c63ff;
}

.footer-contact-option {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 10px;
}

.footer-contact-option svg {
    fill: rgba(255, 255, 255, 0.7);
}

.footer-contact-option a {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
}

.copyright {
    background-color: #001124;
    color: #ededed;
    font-size: 12px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.c1 {
    padding: 0 90px;
    width: 100%;
}