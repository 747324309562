.campaignlist_container .content .flex-display .logo {
  width: 50px;
  height: 40px;
  margin-right: 10px;
  size: 0cap;
}

.campaignlist_container .content .flex-display {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.campaignlist_container .content {
  display: grid;
  grid-template-columns: 0.6fr 0.7fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr 0.4fr;
  column-gap: 20px;
  align-items: center;
  background: #f9fffb;
  border: 1px solid #c5ebc9;
  border-radius: 0px;
  padding: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #222222;
  /* identical to box height */

  /* color: #222222; */
}

.campaignlist_container .content .Select-container .css-1i6u63w-control {
  min-height: auto;
}

.campaignlist_container .content .Select-container .css-dbxmoe-control {
  min-height: auto;
}

.campaignlist_container .content .Select-container {
  margin-bottom: 0px;
}

.campaignlist_container .content .flex-display {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.campaignlist_container .content .right-buttons {
  display: flex;
  align-items: center;
  column-gap: 8px;

  background-color: #ffffff;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;
}

.campaignlist_container .content .right-buttons .copy-button span {
  position: absolute;
  top: 5px;
}

.eye {
  background-color: #ffffff;
  border: none;
  color: darkgreen;
  font-size: medium;
}

.campaignlist_container .preview-overlay .preview-content {
  position: absolute;
  top: 20px;
  left: 70px;
  right: 70px;
  bottom: 10px;
  height: max-content;
}

.campaignlist_container
  .preview-overlay
  .preview-content
  .previewIframe-container {
  height: fit-content;
  padding-bottom: 5px;
}

.campaignlist_container
  .preview-overlay
  .preview-content
  .previewIframe-container
  .preview-box {
  padding: 0px;
  padding-bottom: 5px;
  overflow: none;
  scroll-behavior: none;
}

.campaignlist_container .preview-overlay .preview-content .pagination-section {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
  margin-top: 10px;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  margin-right: 20px;
}

.campaignlist_container
  .preview-overlay
  .preview-content
  .pagination-section
  div {
  border-radius: 50%;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 5px;
  background-color: #c5ebc9;
  cursor: pointer;
  
}

.campaignlist_container .preview-overlay .preview-content .add-margin {
  margin-bottom: 10px;
}

.campaignlist_container .preview-overlay .preview-content .closePreview {
  color: green;
  cursor: pointer;
  font-size: 23px;
  /* font-weight: 800; */
  position: absolute;
  top: 25px;
  right: 16px;
  z-index: 4;
  padding: 0px 5px;
}

.campaignlist_container .preview-overlay .preview-content {
  /* background-color: #ffffff; */
  padding: 10px;
  border-radius: 10px;
  padding-bottom: 50px;
  max-width: 595px;
  margin: 0 auto;
 
}

@media only screen and (max-width: 1265px) {
  .campaignlist_container .content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 20px;
    column-gap: 20px;
    align-items: center;
    background: #f9fffb;
    border: 1px solid #c5ebc9;
    border-radius: 12px;
    padding: 7px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */

    color: #222222;
  }
}

.pdf-download {
  width: 25px;
  padding-left: 10px;
}
