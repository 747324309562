.cm-h2-font-size {
  font-size: 24px;
}

.pdf-text-center-align {
  text-align: center;
}

.pdf-text-right-align {
  text-align: right;
}

.f-w-600 {
  font-weight: 600;
}