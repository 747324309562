@import '../../styles/ConstVariable';

.navigationButtons {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  column-gap: 50px;
  margin-bottom: 20px;
}

.html-css {
  /* display: flex; */
  /* align-items: flex-start; */
  /* align-self: stretch; */
  gap: 20px;
  max-width: max-content;
  display: grid;
  grid-template-columns: max-content max-content;
  width: 100%;
  

}
.social-media-content-container {
  // border: 1px solid #eeeeee;
  // border-radius: 32px;
  // margin-top: 10px;
  margin-right: 30px;
  margin-left: 30px;
  margin-bottom: 10px;
  // width: 100%;
  height: fit-content;
  // background-color: $pageBackgroundColor;
  padding: 10px;
  font-family: $pageFontFamily;
  padding-bottom: 70px;

  .title {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .subHeading {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 4px;
  }
  .subHeading-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #222222;
    margin-bottom: 10px;
  }

  .bottomPos {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 20px;
    row-gap: 20px;
  }

  .rightPos {
    display: grid;
    grid-template-columns: 3fr 1fr;
    max-width: 100%;
    column-gap: 20px;
    row-gap: 20px;
    /* max-width: max-content;
    /* max-width: max-content; */
  }
  .topPos {
    display: flex;
    flex-direction: column-reverse;
    column-gap: 20px;
    row-gap: 20px;
  }

  .content {
    .grid-layout-2 {
      display: grid;
      grid-template-columns: 230px 2fr;
      column-gap: 20px;
      /* justify-self: self-end;*/

      .fix-padding-css {
        padding: 10px !important;
      }
      .fix-headings-css {
        margin-bottom: 0 !important;
      }
      .css-section {
        margin-top: 15px;
        background-color: white;
        padding: 10px 20px 20px 10px;
        border-radius: 20px;
        margin-bottom: 10px;
        .headings {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          cursor: pointer;
          .upload {
            font-size: 16px;
            line-height: 21px;
            cursor: pointer;
            color: #00580a;
          }
          .toggle-icon {
            cursor: pointer;
          }
        }

        .textarea {
          width: 100%;
          height: 345px;
          margin-right: 20px;
          textarea {
            width: 100%;
            height: 345px;
            background: #ffffff;
            border: 1px solid #00580a;
            border-radius: 8px;
            padding: 4px;
          }

          textarea:focus {
            outline: none;
            border: 2px solid #00580a;
          }
        }

        .collapsed {
          display: none;
        }
      }

      .html-section {
        margin-top: 15px;
        background-color: white;
        padding: 10px 20px 20px 10px;
        border-radius: 20px;
        margin-bottom: 10px;

        .headings {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          cursor: pointer;

          .upload {
            font-size: 16px;
            line-height: 21px;
            cursor: pointer;
            color: #00580a;
          }

          .toggle-icon {
            cursor: pointer;
          }
        }

        .textbox-info {
          display: flex;
          column-gap: 30px;
          .inputbox-container {
            width: 100px;
          }
        }
        .textarea {
          width: 100%;
          height: 345px;
          margin-right: 20px;
          textarea {
            width: 100%;
            height: 345px;
            background: #ffffff;
            border: 1px solid #00580a;
            border-radius: 8px;
            padding: 4px;
          }

          textarea:focus {
            outline: none;
            border: 2px solid #00580a;
          }
        }

        .collapsed {
          display: none;
        }
      }

      .add-more-html-box-section {
        .add-more-button {
          margin-bottom: 10px;
          margin-top: 10px;
        }
      }
    }
  }
}
