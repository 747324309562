.pdfAudience-container {

  .server-title-section {
    display: flex;
    align-items: center;
    column-gap: 20px;

    .selection-option {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 160px;
      border-radius: 20px;
      background-color: #ecfcf2;
      border: 2px solid darkgreen;
      margin-right: 10px;
      cursor: pointer;
      transition: all 0.3s ease;
    }
  }
}
