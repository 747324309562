@import '../../styles/ConstVariable';

.title-container {
  margin-bottom: 25px;

  .title {
    font-family: $pageFontFamily;
    // font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    color: #222222;
  }
}
