@import '../../styles/ConstVariable';

.ViewHtmlPreview-container {
  border: 1px solid #eeeeee;
  border-radius: 32px;
  margin-top: 10px;
  margin-right: 10px;
  width: 95%;
  height: fit-content;
  background-color: $pageBackgroundColor;
  padding: 20px;
  font-family: $pageFontFamily;
  padding-bottom: 70px;
  margin-right: 30px;
  margin-left: 30px;

  .noPreview {
    min-height: 60vh;
  }

  .subHeading {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 4px;
  }
}

.subHeading-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #222222;
  margin-bottom: 10px;
}

.flex-layout {
  display: flex;
}

.buttons {
  margin-left: auto;
  display: flex;
  column-gap: 15px;
}

.pagination-section {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
  align-items: center;
}
