	.fwg-large-title {
		font-size: 50px;
	}

	.fwg-font18 {
		font-size: 18px;
	}

	.fwg-font16 {
		font-size: 16px;
	}

	.fwg-font13 {
		font-size: 13px;
	}

	.fwg-font-bold {
		font-weight: bold;
	}

	.fwg-logo-blue {
		color: #2cabe0;
	}

	.fwg-marg-t0 {
		margin-top: 0px;
	}

	.fwg-marg-b20 {
		margin-bottom: 20px;
	}

	.fwg-marg-t40 {
		margin-top: 40px;
	}

	.fwg-settlement-table tr td {
		height: 30px;
		vertical-align: middle;
		padding: 0 5px;
		line-height: 1;
	}

	table.fwg-settlement-table tr td:nth-child(1) {
		font-family: 'Roboto Condensed', sans-serif;
	}

	.fwg-settlement-table.fwg-font13 tr td {
		font-size: 13px;
	}

	.fwg-settlement-flex {
		display: flex;
		gap: 30px;
		margin-bottom: 30px;
	}

	td.fwg-s-title {
		font-size: 11px;
	}

	td.fwg-s-value {
		width: 170px;
		font-size: 13px;
	}