@import '../../styles/ConstVariable';
.SocialMediaDesign-container {
  border: 1px solid #eeeeee;
  border-radius: 32px;
  margin-top: 10px;
  margin-right: 30px;
  margin-right: 30px;
  width: 95%;
  height: fit-content;
  background-color: $pageBackgroundColor;
  padding: 20px;
  font-family: $pageFontFamily;
  padding-bottom: 70px;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  column-gap: 20px;
  .uploadButtons {
    label {
      display: block;
      background-color: #eeeeee;
      border-radius: 10px;
      border: 1px solid black;
      width: fit-content;
      padding: 10px;
      margin-bottom: 10px;
    }
  }

  .design2-container {
    position: relative;
    width: 1080px;
    height: 1080px;

    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 50%;
      z-index: 1;
      display: block;
    }
    .gradient-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.75) 0%,
        rgba(0, 0, 0, 0.45) 56.42%,
        rgba(0, 0, 0, 0.1125) 60%
      );
      z-index: 2;
    }
    .content {
      z-index: 3;
      position: absolute;
      left: 5%;
      top: 30%;
      color: #fff;
    }
    .headline {
      margin-bottom: 20px;
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
    }
    .punchline {
      font-style: normal;
      font-weight: 700;
      font-size: 72px;
    }
    .text {
      font-style: normal;
      font-weight: 600;
      font-size: 27px;

      margin-bottom: 28px;
      width: 50%;
      margin-top: 30px;
    }
    button {
      padding: 15px;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 40px;
      font-weight: 700;
      font-size: 32px;
      line-height: 42px;
      color: #3c3c3c;
      margin-top: 40px;
    }
    button span {
      margin-left: 10px;
    }
    .brand-logo {
      position: absolute;
      bottom: 0;
      right: 5%;
      font-style: normal;
      font-weight: 900;
      font-size: 32px;
      line-height: 42px;
      z-index: 3;
      color: #3c3c3c;
      background: rgba(255, 255, 255, 0.75);
      border-radius: 30px 30px 0px 0px;
      padding: 15px;
    }
  }
}
