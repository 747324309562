.buttons_container {
  display: flex;
  margin-top: 20px;
  gap: 30px;
}
.justify_center {
  justify-content: center;
}
.justify_left {
  justify-content: left;
}
.justify_right {
  justify-content: right;
}
